import { labelOfferContractType } from "../constants/contractType";

export const formatRut = (value) =>
  value
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2");

export const startDateFormated = (value) =>
  new Date(value).toLocaleDateString("es", {
    day: "2-digit",
    month: "long",
  });

export const formatedDate = (value) =>
  new Date(value).toLocaleDateString("es", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

export const formatDateOffer = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-").concat(" ").concat("00:00:00");
};

export const formatDateApprovalFlow = (value) => {
  const date = new Date(value);
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  let hours = new Date(value).getHours();
  let minutes = new Date(value).getMinutes();
  if (String(hours).length < 2) hours = `0${hours}`;
  if (String(minutes).length < 2) minutes = `0${minutes}`;

  return `${[day, month, year].join("/")} a las ${hours}:${minutes}h`;
};

export const getTodayDate = () => {
  const date = new Date();
  const month = `${date.getMonth() + 1}`;
  const day = date.getDate().toString();
  const year = date.getFullYear().toString();

  return new Date(`${year}-${month}-${day}`);
};

export const numberToDecimal = (value) =>
  new Intl.NumberFormat([], {
    style: "decimal",
  }).format(value);

export const formatDate = (dateAux, format) => {
  const date = new Date(dateAux);
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  if (format === "YYYYMMDD") return [year, month, day].join("");
  if (format === "DD-MM-YYYY") return [day, month, year].join("-");
  if (format === "DD/MM/YYYY") return [day, month, year].join("/");

  return [year, month, day].join("-");
};

export const getFileType = (type) => {
  let fileType = "";
  switch (type) {
    case ".pdf":
      fileType = "application/pdf";
      break;
    case ".png":
      fileType = "image/png";
      break;
    case ".jpg":
      fileType = "image/jpeg";
      break;
    case ".docx":
      fileType =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      break;
    case ".xlsx":
      fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      break;
    default:
      fileType = "text/csv";
      break;
  }

  return fileType;
};

export const getTypeDoc = (type) => {
  let typeDoc = "";
  switch (Number(type)) {
    case 1:
      typeDoc = ".pdf";
      break;
    case 2:
      typeDoc = ".png";
      break;
    case 3:
      typeDoc = ".jpg";
      break;
    case 4:
      typeDoc = ".docx";
      break;
    case 5:
      typeDoc = ".csv";
      break;
    default:
      typeDoc = " ";
      break;
  }

  return typeDoc;
};

export const getRequiredTo = (required) => {
  let requiredTo = "";
  switch (Number(required)) {
    case 1:
      requiredTo = "Proveedor";
      break;
    case 2:
      requiredTo = "Courier";
      break;
    case 3:
      requiredTo = "Flota";
      break;
    default:
      requiredTo = " ";
      break;
  }

  return requiredTo;
};

export const formatOfferContractType = (contractType) => {
  return labelOfferContractType[contractType] ?? labelOfferContractType.factura;
}
