import { useState, useContext, createContext, useMemo } from "react";

const CreateOfferContext = createContext();

export const initialDescription = {
  title: "",
  commercialOffer: "",
  structureId: "",
  privateCommercialOffer: true,
  description: "",
  requirements: "",
  details: "",
  cyber: false,
  priority: 3,
};

export const initialOfferConfig = {
  vacancy: [],
  vehicleType: [],
  dateExibition: [null, null],
  dateSelection: [null, null],
};

export const initialApplicationConfig = {
  fullRecord: false,
  documentRecord: false,
  docOffer: [],
};

export const delVacancyVeichles = {
  deleteVacancy: [],
  deleteVehicle: [],
  deleteOffer: [],
};

export const CreateOfferProvider = ({ children }) => {
  const [description, setDescription] = useState(initialDescription);
  const [offerConfig, setOfferConfig] = useState(initialOfferConfig);
  const [deletes, setDeletes] = useState(delVacancyVeichles);
  const [applicationConfig, setApplicationConfig] = useState(
    initialApplicationConfig
  );
  const [status, setStatus] = useState(1);
  const [isUpdating, setIsUpdating] = useState(false);
  const [offerId, setOfferId] = useState(0);
  const [codeOffer, setCodeOffer] = useState("");

  const value = useMemo(
    () => ({
      description,
      offerConfig,
      applicationConfig,
      delVacancyVeichles,
      isUpdating,
      offerId,
      deletes,
      codeOffer,
      status,
      setDescription,
      setOfferConfig,
      setApplicationConfig,
      setIsUpdating,
      setOfferId,
      setDeletes,
      setCodeOffer,
      setStatus,
    }),
    [
      description,
      offerConfig,
      applicationConfig,
      delVacancyVeichles,
      isUpdating,
      offerId,
      deletes,
      status,
      codeOffer,
    ]
  );

  return (
    <CreateOfferContext.Provider value={value}>
      {children}
    </CreateOfferContext.Provider>
  );
};

export const useCreateOffer = () => {
  const context = useContext(CreateOfferContext);

  return context;
};
