import { useEffect, useState } from "react";
import { useSession } from "@blue-express/bx-lib-universal-frontend";
import { useParams, useNavigate } from "react-router-dom";
import {
  AddCircleOutlined,
  ArrowBack,
  DeleteOutlined,
  Edit,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { SearchBar } from "../../components/SearchBar";
import { Table } from "../../v2/components/Table";
import { ProtectedComponent } from "../../components/ProtectedComponent";
import { AlertSnackBar } from "../../components/Alerts";
import { Dialogs } from "../../components/Dialogs";
import { PROVIDER_APPLY_ADD } from "../../constants/functionalities";
import { maskRUT } from "../../utils/masks";

import {
  useDeleteHardEmployee,
  useListEmployeeByApply,
} from "../../services/hooks/Employee";
import { useMyProvider } from "../../services/hooks/Providers/useMyProvider";
import { StepperController } from "../../components/Stepper";
import { AddNew } from "../../components/Forms/AddNew";
import employeeImg from "../../assets/images/datos-empleados.png";
import CreateEmployee from "./components/CreateEmployee";
import { employeeType } from "../../constants/employeeType";
import {
  EMPLOYEE_TYPE_ENUM,
  EmployeeStatus,
  VACANCY_TYPE_MAPPING,
} from "../../constants/status";
import { steps } from "../../constants/steps";
import { useUpdateApplyStatus } from "../../services/hooks/Apply/useUpdateApplyStatus";
import styles from "../../v2/components/TabsAddon/TabsAddon.module.css";
import { ChipStatus } from "../../v2/components/Chip";
import { offerContractType } from "../../constants/contractType";

const default_error = "Oh no! se ha producido un error en el proceso";

const headCells = [
  {
    id: "name",
    label: "Nombre",
    sort: {
      default: true,
      id: "employeeIds.name",
      order: "asc",
    },
  },
  {
    id: "numberRut",
    label: "RUT",
    sort: {
      id: "numberRut",
    },
  },
  {
    id: "email",
    label: "Correo",
    sort: {
      id: "employeeIds.email",
    },
  },
  {
    id: "type",
    label: "Tipo",
    sort: {
      id: "employeeIds.type",
    },
  },
  {
    id: "status",
    label: "Estado",
    sort: {
      id: "employeeIds.status",
    },
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
  },
];

const initialAlertError = {
  open: false,
  message: "",
};

export default function ListEmployee() {
  const { getSession } = useSession();
  const navigate = useNavigate();
  const { step, applyId } = useParams();
  const [alertError, setAlertError] = useState(initialAlertError);
  const [openDialogDeleteEmployee, setOpenDialogDeleteEmployee] =
    useState(false);
  const [deleteEmployeeId, setDeleteEmployeeId] = useState("");
  const [isRenderedForm, setIsRenderedForm] = useState(false);
  const [employeeUpdate, setEmployeeUpdate] = useState();
  const [employees, setEmployees] = useState([]);
  const [metadata, setMetadata] = useState({});

  const session = getSession();
  const providerEmail = session?.username;
  const { data: myProvider, isLoading: isProviderLoading } =
    useMyProvider(providerEmail);
  const [filters, setFilters] = useState({});
  const {
    data,
    refetch,
    isLoading: isGettingList,
  } = useListEmployeeByApply(applyId, filters);
  const { mutate: deleteEmployeeMutate, isLoading: deleteEmployeeIsLoading } =
    useDeleteHardEmployee();
  const { mutate: updateApplyMutate, isLoading: isUpdatingApply } =
    useUpdateApplyStatus(applyId);

  const { TableContainer, setTableLoading, setPage } = Table(
    headCells,
    metadata,
    onChangeFilters
  );

  useEffect(() => {
    setTableLoading(isGettingList);
    if (data) {
      setEmployees(data.data);
      setMetadata(data.metadata);
    }
  }, [data, isGettingList]);

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    setFilters((old) => ({ ...old, search: newValue, page: 1 }));
    setPage(0);
  };

  useEffect(() => {
    if (myProvider && filters.hasOwnProperty("providerId")) {
      refetch();
    }
  }, [filters, myProvider]);

  function onChangeFilters(newFilters = {}) {
    setFilters((old) => ({
      ...old,
      ...newFilters,
      providerId: myProvider?.id,
    }));
  }

  const handleCloseAlertError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertError(initialAlertError);
  };

  const handleDeleteEmployee = () => {
    if (deleteEmployeeId) {
      deleteEmployeeMutate(
        { deleteEmployeeId, employees, applyId },
        {
          onSuccess: () => {
            setDeleteEmployeeId("");
            setOpenDialogDeleteEmployee(false);
          },
        }
      );
    }
  };

  const handleCloseDialogDeleteEmployee = () => {
    setOpenDialogDeleteEmployee(false);
  };

  const goToFinishStep = () => {
    updateApplyMutate(null, {
      onSuccess: () => navigate("/finish/3"),
      onError: (error) => {
        setAlertError({
          open: true,
          message: error?.response?.data?.message || default_error,
        });
      },
    });
  };

  const displayAddEmployee = () => {
    return myProvider?.contractType !== offerContractType.BOLETA;
  };

  const handleGoBack = () => {
    setIsRenderedForm(false);
  };
  if (isRenderedForm) {
    return (
      <>
        <StepperController steps={steps} isCurrentStepActive={parseInt(step)} />
        <CreateEmployee
          goBack={handleGoBack}
          applyId={applyId}
          employee={employeeUpdate}
          isBoleta={
            myProvider?.contractType === offerContractType.BOLETA || false
          }
        />
      </>
    );
  }

  if (isGettingList || isProviderLoading || isUpdatingApply)
    return <LoadingSpinner open />;

  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
      className={styles.tabAddon}
    >
      <Box sx={{ display: "flex" }}>
        <SearchBar
          id="table-searchbar"
          type="text"
          placeholder="buscar"
          onSearch={handleSearch}
        />
      </Box>
      {displayAddEmployee() && (
        <ProtectedComponent functionality={[PROVIDER_APPLY_ADD]}>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlined color="inherit" />}
            onClick={() => {
              setEmployeeUpdate(null);
              setIsRenderedForm(true);
            }}
          >
            Agregar Nuevo
          </Button>
        </ProtectedComponent>
      )}
    </Stack>
  );
  return (
    <>
      <StepperController steps={steps} isCurrentStepActive={parseInt(step)} />
      {employees.length ? (
        <Stack spacing={2}>
          <TableContainer addons={[TabsAddon]} withPagination>
            <TableBody>
              {employees.map((employee) => (
                <TableRow hover tabIndex={-1} key={employee.id}>
                  <TableCell>
                    {employee.name
                      ?.concat(" ")
                      ?.concat(employee?.lastname1 ?? "")
                      ?.concat(" ")
                      ?.concat(employee?.lastname2 ?? "")}
                  </TableCell>
                  <TableCell>
                    {maskRUT(
                      employee.employeeType === employeeType.COURIER
                        ? employee.courierDni
                        : employee.peonetaDni
                    )}
                  </TableCell>
                  <TableCell sx={{ opacity: 1 }}>{employee.email}</TableCell>
                  <TableCell sx={{ opacity: 1 }}>
                    <Chip
                      label={VACANCY_TYPE_MAPPING[employee.employeeType]}
                      color={
                        employee.employeeType === EMPLOYEE_TYPE_ENUM.COURIER
                          ? "primary"
                          : "secondary"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <ChipStatus
                      label={EmployeeStatus[employee.status]}
                      setcolor={employee.status}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row">
                      <IconButton
                        onClick={() => {
                          setEmployeeUpdate(employee);
                          setIsRenderedForm(true);
                        }}
                        disabled={employee.status !== EmployeeStatus.DRAFT}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setOpenDialogDeleteEmployee(true);
                          setDeleteEmployeeId(employee.id);
                        }}
                        disabled={employee.status !== EmployeeStatus.DRAFT}
                      >
                        <DeleteOutlined />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
        </Stack>
      ) : (
        <AddNew
          handleCreate={() => {
            setEmployeeUpdate(null);
            setIsRenderedForm(true);
          }}
          title={"Aún no tienes empleados en tu lista"}
          description={
            "Elige una de las opciones para registrar los empleados."
          }
          button={"Agregar un empleado"}
          image={employeeImg}
        />
      )}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 5 }}
      >
        <Button
          variant="goBack"
          startIcon={<ArrowBack />}
          onClick={() => navigate(`/application/${applyId}/fleet/1`)}
        >
          Volver atrás
        </Button>
        <Button
          color="secondary"
          disabled={!employees.length}
          sx={{ minWidth: "155px", height: 40 }}
          onClick={goToFinishStep}
        >
          Finalizar
        </Button>
      </Stack>
      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />
      <Dialogs
        open={openDialogDeleteEmployee}
        handleClose={handleCloseDialogDeleteEmployee}
        handleConfirmation={handleDeleteEmployee}
        isLoading={deleteEmployeeIsLoading}
        title="¿Estás seguro de que quieres eliminar el empleado de esta lista?"
      />
    </>
  );
}
