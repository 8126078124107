import { useNavigate } from "react-router-dom";
import { Stack, Typography, Button } from "@mui/material";
import { PaperWhite } from "../../components/Papers";
import { UserIcon } from "../../components/Icons/UserIcon";
import { StoreIcon } from "../../components/Icons/StoreIcon";
import { maskRUT } from "../../utils/masks";
import { useMyProvider } from "../../services/hooks/Providers/useMyProvider";
import { useAuth } from "../../hook/useAuth";
import { EmployeeStatus } from "../../constants/status";
import { DocumentListUpdate } from "../../v2/components/Documents/DocumentListUpdate";
import { templateNames } from "../../constants/templateNames";
import { vacancyType } from "../../constants/vacancyType";
import { useGetTemplateByName } from "../../services/hooks/Docs";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { ChipStatus } from "../../v2/components/Chip";


export const Profile = () => {
  const { email } = useAuth();
  const navigate = useNavigate();
  const { data: provider, isLoading } = useMyProvider(email);
  const { data } = useGetTemplateByName(templateNames.provider)

  const {
    name,
    numberRut,
    phone,
    address: { street, number, city, commune: communeAddress },
    legalRepresentative: {
      name: nameLegalRepresentative,
      lastname1: lastnameLegalRepresentative1,
      lastname2: lastnameLegalRepresentative2,
    },
    status,
  } = provider || { legalRepresentative: {}, address: {} };

  const goToEditProfile = () => {
    navigate("/profile/edit");
  };

  if (isLoading) return <LoadingSpinner open />

  return (
    <>
      <PaperWhite>
        <Stack
          direction="row"
          gap="2em"
          justifyContent="space-around"
          flexWrap="wrap"
        >
          <Stack>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                mb: 3,
              }}
            >
              <StoreIcon sx={{ width: 30, height: 30 }} />
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                Datos del proveedor
              </Typography>
            </Stack>
            <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
              Estado:{" "}
              <ChipStatus
                label={EmployeeStatus[status]}
                setcolor={status}
              />
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
              Razón Social: <b>{name}</b>
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
              RUT: <b>{maskRUT(numberRut)}</b>
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
              Direccíon:{" "}
              <b>{`${street} ${number}, ${communeAddress}, ${city}`}</b>
            </Typography>
          </Stack>
          <Stack>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                mb: 3,
              }}
            >
              <UserIcon sx={{ width: 30, height: 30 }} />
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                Representate Legal
              </Typography>
            </Stack>
            <Typography variant="syubtitle1" sx={{ fontWeight: 400 }}>
              Nombre:{" "}
              <b>{`${nameLegalRepresentative} ${
                lastnameLegalRepresentative1 ?? ""
              } ${lastnameLegalRepresentative2 ?? ""
              }`}</b>
            </Typography>
            <Typography variant="syubtitle1" sx={{ fontWeight: 400 }}>
              Teléfono: <b>{phone}</b>
            </Typography>
            <Typography variant="syubtitle1" sx={{ fontWeight: 400 }}>
              E-mail: <b>{email}</b>
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
              <Button
                onClick={goToEditProfile}
                autoFocus
                color="secondary"
                variant="contained"
                sx={{
                  color: "white",
                  borderRadius: "16px",
                  fontWeight: 700,
                  textTransform: "capitalize",
                  width: 100,
                  height: 40,
                }}
                disableElevation
              >
                Editar
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </PaperWhite>
      <Stack sx={{mt:10}}>
        <DocumentListUpdate type={vacancyType.EMPLOYEE} id={provider?.id} templateData={data} status={status}/>
      </Stack>
    </>
  );
};
