import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetApplyById } from "../../../services/hooks/Apply/useGetApplyById";
import { PaperWhite } from "../../../components/Papers";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { FormCreate } from "../../../components/Forms/Employer/FormCreateEmployee";
import { employeeType } from "../../../constants/employeeType";
import { Button } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import { templateNames } from "../../../constants/templateNames";
import { validateGroupedDocuments } from "../../helper/documents";
import { useUploadDocuments } from "../../../services/hooks/Docs/S3/useUploadDocument";
import { vacancyType } from "../../../constants/vacancyType";
import { Alert } from "../../../components/Alert";
import { EmployeeStatus, VACANCY_TYPE_MAPPING } from "../../../constants/status";
import { useSession } from "@blue-express/bx-lib-universal-frontend";
import { useMyProvider } from "../../../services/hooks/Providers/useMyProvider";
import { useReplaceEmployeeVacancy } from "../../services/hooks/Employee/useReplaceEmployeeVacancy";

const initialAlertError = {
  open: false,
  title: "",
  messages: [],
  content: "",
};

export default function ReplaceEmployee() {
  const { applyId, employeeId } = useParams();
  const { getSession } = useSession();
  const session = getSession();
  const providerEmail = session?.username;
  const { data: provider, isLoading: providerLoading } = useMyProvider(providerEmail);
  const navigate = useNavigate();
  const [newEmployee, setNewEmployee] = useState({
    rut: "",
    name: "",
    lastname1: "",
    lastname2: "",
    email: "",
    phone: "",
    peoneta: false,
    securityConditions: false,
    termsAndConditions: false,
  });
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [alertError, setAlertError] = useState(initialAlertError);

  const {
    data: currentApply,
    isLoading: isGettingApplies,
    isSuccess: isApplySuccess,
  } = useGetApplyById(applyId, provider.id);
  const { mutate: mutateUploadDocument, isLoading: isLoadingUpload } =
    useUploadDocuments();
  const { mutate: replaceEmployeeMutate, isLoading: replaceEmployeeLoading } =
    useReplaceEmployeeVacancy();

  const { employeeToReplace, fleet } = useMemo(() => {
    if (currentApply) {
      const employeeToReplace = currentApply?.employeeIds?.find(
        (employee) => employee.id === employeeId
      );
      const fleet = currentApply?.fleetIds?.find(
        (fleet) => fleet.plate === employeeToReplace.plate
      );
      return { employeeToReplace, fleet };
    }
    return { employeeToReplace: null, fleet: null };
  }, [currentApply]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSubmit = useCallback(
    async (employee) => {
      const offerVacancyLinked = currentApply?.offer?.setting?.vacancies.find(
        ({ vehicle }) => vehicle.name === employee.fleet?.fleetType
      );

      const data = {
        name: employee.name,
        lastname1: employee.lastname1,
        lastname2: employee.lastname2,
        numberRut: employee.rut,
        phone: employee.phone,
        email: employee.email,
        contractType: provider.contractType,
        type: employee.isPeoneta ? employeeType.PEONETA : employeeType.COURIER,
        subcontract: false,
        providerId: provider.id,
        documentTemplateId: employee.isPeoneta
          ? templateNames.peoneta
          : offerVacancyLinked?.vehicle?.courierTemplateId,
        status: EmployeeStatus.BACKOFFICEAPPROVED,
        apply: {
          id: applyId,
        }
      };

      const { error: validationGroupedError, groupedDataByDocumentType } =
        validateGroupedDocuments(currentTemplate.documentTypes, employee);

      if (validationGroupedError) {
        setAlertError({
          ...alertError,
          open: true,
          title: "Error",
          messages: validationGroupedError.messages,
        });
        return;
      }

      replaceEmployeeMutate(
        {
          applyId,
          idToReplace: employeeId,
          newEmployee: data,
        },
        {
          onSuccess: (replacedEmployee) => {
            Object.entries(groupedDataByDocumentType).forEach(
              ([key, documentToUpload]) => {
                const beforeUpload = Object.assign({}, documentToUpload);
                beforeUpload["ownerId"] = replacedEmployee?.newId;
                beforeUpload["ownerType"] = vacancyType.EMPLOYEE;
                beforeUpload.metaData = JSON.stringify(beforeUpload.metaData);

                mutateUploadDocument(beforeUpload, {
                  onSuccess: () => {},
                  onError: () => {
                    setAlertError({
                      ...alertError,
                      open: true,
                      title: "Error",
                      messages: [
                        "Oh no! se ha producido un error al cargar los documentos",
                      ],
                    });
                  },
                });
              }
            );
          },
          onError: () => {
            setAlertError({
              ...alertError,
              open: true,
              title: "Error",
              messages: [
                `Oh no! se ha producido un error al crear ${
                  employee.peoneta
                    ? VACANCY_TYPE_MAPPING.peoneta
                    : VACANCY_TYPE_MAPPING.courier
                }`,
              ],
            });
          },
        }
      );
    },
    [currentApply, currentTemplate, provider]
  );

  const handleConfirmation = () => {
    setAlertError({
      ...alertError,
      open: false,
    });
  };

  useEffect(() => {
    if (employeeToReplace && isApplySuccess) {
      setNewEmployee((prev) => ({
        ...prev,
        peoneta: employeeToReplace.employeeType === employeeType.PEONETA ?? false,
      }));
    }
  }, [employeeToReplace, isApplySuccess]);

  if (
    isGettingApplies ||
    !fleet ||
    !employeeToReplace ||
    isLoadingUpload ||
    providerLoading ||
    replaceEmployeeLoading
  ) {
    return (
      <PaperWhite>
        <LoadingSpinner open />
      </PaperWhite>
    );
  }

  return (
    <>
      <Button
        variant="goBack"
        disableRipple
        startIcon={<ArrowBackOutlined />}
        onClick={handleGoBack}
        sx={{ height: 40, fontSize: 12, alignSelf: "self-start" }}
      >
        {"Volver atrás"}
      </Button>
      <PaperWhite sx={{ py: 4, mt: 3 }}>
        <FormCreate
          formValues={newEmployee}
          onSubmit={handleSubmit}
          goBack={handleGoBack}
          currentApply={currentApply}
          setTemplate={setCurrentTemplate}
          defaultValues={{
            isPeoneta: newEmployee?.peoneta,
            fleet,
          }}
          isReplacing
        />
      </PaperWhite>
      <Alert
        icon
        open={alertError.open}
        handleConfirmation={handleConfirmation}
        title={alertError.title}
        messages={alertError.messages}
      />
    </>
  );
}
