import { atom } from "jotai";
import { entityfilters } from "../../pages/Requests/RequestBackoffice";
import { EmployeeStatus } from "../../../constants/status";

export const replaceFleetDataAtom = atom({
  validate: {
    plate: "",
    isValid: false,
  },
  plate: "",
  providerId: "",
  model: "",
  brand: "",
  type: "",
  documentTemplateId: "",
  year: "",
});

export const replaceEmployeeDataAtom = atom({
  rut: "",
  name: "",
  lastname1: "",
  lastname2: "",
  phone: "",
  email: "",
  fleet: {},
});

export const fleetBrandDataAtom = atom({
  name: "",
  model: "",
});

export const replaceDataAtom = atom({
  isPermanent: false,
  replaceReason: "",
  fromDate: null,
  toDate: null,
  observations: "",
});

export const filtersRequestsAtom = atom({
  page: 1,
  limit: 10,
  ...entityfilters.courier,
});

export const filtersSecurityRequestsAtom = atom({
  page: 1,
  limit: 10,
  status: EmployeeStatus.BACKOFFICEAPPROVED,
  ...entityfilters.courier,
});
