import { useQuery } from 'react-query'
import { CONTEXT_MESSAGE_URI } from '../../../../constants/api'
import { useApi } from '../../../../services/api'

export async function getContextMessages() {
  const { axiosInstance } = useApi()
  const response = await axiosInstance.get('/', {
    baseURL: CONTEXT_MESSAGE_URI,
  })
  const { data } = response
  return data
}

export function useGetContextMessages() {
  return useQuery(['contextMessages'], () => getContextMessages(), {
    staleTime: 1000 * 60 * 10,
  })
}
