import { useCallback, useEffect, useState } from "react";
import { PaperWhite } from "../../../../components/Papers";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Stack,
  Typography,
  Button,
  Grid,
  Divider,
  Autocomplete,
} from "@mui/material";
import { useValidatePlate } from "../../../../services/hooks/Fleets/useValidatePlate";
import { useGetTemplate } from "../../../../services/hooks/Docs/Templates/useGetTemplates";
import { STATUS_CODE, VACANCY_TYPE_ENUM, VACANCY_TYPE_MAPPING } from "../../../../constants/status";
import { Input } from "../../../../components/Inputs";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { maskPlate } from "../../../../utils/masks";
import { ReactComponent as LeftArrow } from "../../../assets/images/leftarrow.svg";
import { DocumentsSection } from "../../../../components/Forms/Documents/DocumentSection";
import fleetImg from "../../../../assets/images/datos-vehiculos.png";
import {
  isNumberValid,
  validateMinValue,
} from "../../../../utils/validateValues";
import { useListVehiclesBrand } from "../../../../services/hooks/Vehicles/Brands/useListVehiclesBrand";
import { useAtom } from "jotai";
import { replaceFleetDataAtom } from "../../../store/requestTracker/replaceRequest.store";
import SwitchButton from "../../../components/Button/SwitchButton";
import Fleetable from "./FleetTable";

export const FleetForm = ({ item, goBack, onSubmit, setTemplate, setReplaceFinish }) => {
  const { control, handleSubmit, setValue, formState } = useForm({
    mode: "onChange",
  });
  const [switchLeft, setSwitchLeft] = useState(true);

  const minLengthAllowed = 4;
  const maxLengthAllowed = 6;

  const { data: template, isLoading: isLoadingTemplate } = useGetTemplate(
    item.documentTemplateId
  );
  useEffect(() => {
    setTemplate(template);
    setFleetData((prevState) => ({
      ...prevState,
      documentTemplateId: item.documentTemplateId, 
    }));
  }, [template]);

  const {
    mutate: getPlate,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useValidatePlate();

  const [fleetData, setFleetData] = useAtom(replaceFleetDataAtom);
  const [models, setModels] = useState([]);

  const validatePlate = useCallback(
    (plate) => {
      const isValid = getPlate(plate);
      setFleetData((prevState) => ({
        ...prevState,
        type: item.type,
        plate: plate,
      }));
      return isValid;
    },
    [setFleetData, fleetData]
  );

  const plateHasError = useCallback(
    () =>
      (isError && error?.response?.status !== STATUS_CODE.NOT_FOUND) ||
      isSuccess,
    [isError, error, isSuccess]
  );

  useEffect(() => {
    if (!plateHasError() && fleetData.plate) {
      setFleetData((prevState) => ({
        ...prevState,
        validate: { plate: prevState.plate, isValid: true },
      }));
    }
  }, [plateHasError, fleetData.plate]);

  const { data: dataVehicle, isLoading: isLoadingVehicle } =
    useListVehiclesBrand();

  const updateFormField = (field, onChange) => (event) => {
    let value;
    if (field === "type") {
      value = event.target.value;
    } else {
      value = event.target.value;
    }
    setFleetData((prevState) => ({ ...prevState, [field]: value }));
    onChange && onChange(event);
  };

  if (isLoadingTemplate || isLoadingVehicle) return <LoadingSpinner open />;

  return (
    <>
      <Box sx={{display:"flex", justifyContent:"center"}}>
        <SwitchButton
          switchLeft={switchLeft}
          setSwitchLeft={setSwitchLeft}
          titleLeft={`Ingresar nuevo ${VACANCY_TYPE_MAPPING[VACANCY_TYPE_ENUM.FLEET].toLowerCase()}`}
          titleRight={`Seleccionar ${VACANCY_TYPE_MAPPING[VACANCY_TYPE_ENUM.FLEET].toLowerCase()} disponible`}
        />
      </Box>
      <PaperWhite sx={{ py: 4, mt: 3 }}>
        {switchLeft ? 
        <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
            <Box gridColumn={{ xs: "span 12" }}>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                Datos del Vehículo de reemplazo
              </Typography>
            </Box>
            <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
              <Controller
                control={control}
                name="plate"
                defaultValue={fleetData.plate}
                rules={{
                  required: "(*) Campo obligatorio",
                  validate: plateHasError(),
                }}
                render={({ field: { value, onChange, ...rest } }) => (
                  <>
                    <Input
                      value={value}
                      onChange={(e) => {
                        e.target.value = maskPlate(e.target.value);
                        updateFormField("plate", onChange)(e);
                      }}
                      label="Patente"
                      isRequired
                      error={Boolean(plateHasError())}
                      helperText={
                        Boolean(plateHasError()) &&
                        "Esta patente ya esta registrada en nuestro sistema!"
                      }
                      {...rest}
                    />
                    <Box width={100} mt={1}>
                      <Button
                        disabled={
                          value?.length < minLengthAllowed ||
                          value?.length > maxLengthAllowed
                        }
                        onClick={() => validatePlate(value)}
                      >
                        {"Validar"}
                      </Button>
                    </Box>
                  </>
                )}
              />
            </Box>
            <Box
              gridColumn={{ xs: "span 0", lg: "span 4" }}
              component="img"
              alt="Vehículo"
              src={fleetImg}
              sx={{
                display: { xs: "none", lg: "block" },
                height: "200px",
                marginTop: "-50px",
                marginLeft: "auto",
              }}
            />
          </Box>
          {fleetData.validate.isValid && (
            <Box display="grid" sx={{ gap: { xs: 2 } }}>
              <Divider sx={{ pt: 1, pb: 2 }} />
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)"  gap={2}>
                <Box gridColumn="span 8" >
                  <Box display="grid" gridTemplateColumns="repeat(12, 1fr)"  gap={2}>
                    <Box gridColumn="span 6">
                <Controller
                  control={control}
                  defaultValue={fleetData.type}
                  name="type"
                  rules={{
                    required: "(*) Campo obligatorio",
                  }}
                  render={({ field: { value, onChange, ...rest } }) => {
                    return (
                      <Input
                        value={item.type}
                        label="Tipo de Vehículo"
                        disabled={true}
                        {...rest}
                      />
                    );
                  }}
                />
                    </Box>
                    <Box gridColumn="span 6">
                    <Controller
                      control={control}
                      defaultValue={fleetData.brand}
                      name="brand"
                      rules={{
                        required: "(*) Campo obligatorio",
                      }}
                      render={({
                        field: { value, onChange, ...rest },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          size="small"
                          value={value || null}
                          disableClearable
                          required
                          onChange={(e, newValue) => {
                            setValue("model", "");
                            setModels(newValue.models);
                            onChange(newValue);
                            setFleetData((prevState) => ({
                              ...prevState,
                              brand: newValue.name,
                              model: "",
                            }));
                          }}
                          options={dataVehicle?.concat([
                            {
                              name: "Otro",
                              models: [],
                            },
                          ])}
                          getOptionLabel={(option) =>
                            option && option?.name ? option?.name : ""
                          }
                          isOptionEqualToValue={(option, value) =>
                            option && option.id === value.id
                          }
                          renderInput={(params) => (
                            <Input
                              {...params}
                              isRequired
                              label="Marca"
                              error={Boolean(error)}
                              helperText={error?.message}
                            />
                          )}
                          {...rest}
                        />
                      )}
                    />
                    </Box>
                    <Box gridColumn="span 6">
                    <Controller
                      control={control}
                      defaultValue={fleetData.model || ""}
                      name="model"
                      rules={{
                        required: "(*) Campo obligatorio",
                      }}
                      render={({
                        field: { value, onChange, ...rest },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          size="small"
                          value={value || null}
                          disableClearable
                          required
                          onChange={(e, newValue) => {
                            onChange(newValue);
                            setFleetData((prevState) => ({
                              ...prevState,
                              model: newValue.name,
                            }));
                          }}
                          options={(models ?? []).concat([
                            {
                              name: "Otro",
                            },
                          ])}
                          getOptionLabel={(option) =>
                            option && option?.name ? option.name : ""
                          }
                          isOptionEqualToValue={(option, value) =>
                            option && option.id === value.id
                          }
                          renderInput={(params) => (
                            <Input
                              {...params}
                              isRequired
                              label="Modelo"
                              error={Boolean(error)}
                              helperText={error?.message}
                            />
                          )}
                          {...rest}
                        />
                      )}
                    />
                    </Box>
                    <Box gridColumn="span 6">
                    <Controller
                      control={control}
                      name="year"
                      defaultValue={fleetData.year}
                      rules={{
                        required: "(*) Campo obligatorio",
                        validate: {
                          textIsValid: (text) =>
                            isNumberValid(text) ||
                            "Año inválido, solo se aceptan números",
                          minValueIsValid: (num) =>
                            validateMinValue(num, 1980) ||
                            `Año inválido, debe ser desde 1980`,
                        },
                      }}
                      render={({
                        field: { value, onChange, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <Input
                            value={fleetData.year}
                            label="Año"
                            onChange={updateFormField("year", onChange)}
                            isRequired
                            error={Boolean(error)}
                            helperText={error?.message}
                            {...rest}
                          />
                        </>
                      )}
                    />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ pt: 1, pb: 2 }} />
              <DocumentsSection
                control={control}
                documentStructure={template?.documentTypes}
              />
            </Box>
          )}

          <Grid container sx={{ pt: 2 }}>
            <Grid item xs={5}>
              {goBack && (
                <Button
                  variant="goBack"
                  disableRipple
                  startIcon={<LeftArrow />}
                  onClick={goBack}
                  sx={{ height: 40, fontSize: 12, alignSelf: "self-start" }}
                >
                  <Typography
                    fontFamily="Lato"
                    fontWeight={800}
                    fontSize="12px"
                    lineHeight="16.2px"
                  >
                    {"Volver atrás"}
                  </Typography>
                </Button>
              )}
            </Grid>
            <Grid item xs={7}>
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="submit"
                  sx={{ minWidth: "190px", height: 40 }}
                  disabled={!formState.isValid}
                >
                  <Typography
                    fontFamily="Lato"
                    fontWeight={700}
                    fontSize="14px"
                    lineHeight="16.8px"
                    color="#FFFFFF"
                  >
                    {"Finalizar"}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        :
        <>
          <Fleetable item={item} goBack={goBack} setReplaceFinish={setReplaceFinish}/>
        </>
        }
      </PaperWhite>
      {isLoading && <LoadingSpinner open />}
    </>
  );
};
