import {
  Box,
  Stack,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { Input } from '../../Inputs'
import { validateRut } from '../../../utils/validateRut'
import { maskRUT, maskTel } from '../../../utils/masks'
import { isComplexTextValid, validateMinLength } from '../../../utils/validateValues'

export const ProviderBoletaSection = ({ control, getValue, isEditing }) => {

  return (
    <Stack spacing={2} sx={{mt:2}}>
      <Box
        display='grid'
        gridTemplateColumns='repeat(12, 1fr)'
        gap={6}
      >
        <Box gridColumn={{ xs: 'span 12', lg: 'span 12' }}>
          <Typography variant='h6' sx={{ fontWeight: 700 }}>
            Datos del proveedor
          </Typography>
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            name='providerBoletaName'
            rules={{
              required: '(*) Campo obligatorio',
              validate: {
                textIsValid: (text) =>
                  isComplexTextValid(text) ||
                  'Nombre inválido, no se aceptan caracteres extraños ni dígitos',
              },
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={value || ''}
                disabled={isEditing}
                onChange={onChange}
                label='Nombre'
                fullWidth
                isRequired
                error={Boolean(error)}
                helperText={error?.message}
                {...rest}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            name='providerBoletaLastName1'
            rules={{
              required: '(*) Campo obligatorio',
              validate: {
                textIsValid: (text) =>
                  isComplexTextValid(text) ||
                  'Nombre inválido, no se aceptan caracteres extraños ni dígitos',
              },
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={value || ''}
                onChange={onChange}
                disabled={isEditing}
                label='Apellido Paterno'
                isRequired
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 55 }}
                {...rest}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            name='providerBoletaLastName2'
            rules={{
              required: '(*) Campo obligatorio',
              validate: {
                textIsValid: (text) =>
                  isComplexTextValid(text) ||
                  'Nombre inválido, no se aceptan caracteres extraños ni dígitos',
              },
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={value || ''}
                onChange={onChange}
                disabled={isEditing}
                label='Apellido Materno'
                isRequired
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 55 }}
                {...rest}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            name='providerBoletaRut'
            rules={{
              required: '(*) Campo obligatorio',
              validate: {
                rutIsValid: (rut) =>
                  validateRut(maskRUT(rut)) || 'RUT inválido',
              },
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={maskRUT(value) || ''}
                disabled={isEditing}
                onChange={(e) => {
                  e.target.value = maskRUT(e.target.value)
                  onChange(e)
                }}
                label='RUT'
                isRequired
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 12 }}
                {...rest}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            defaultValue=''
            name='providerBoletaEmail'
            rules={{
              required: '(*) Campo obligatorio',
              validate: (value) => {
                const validEmailRegex =
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                return validEmailRegex.test(value) || 'Email inválido'
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                label='Correo electrónico'
                disabled
                fullWidth
                isRequired
                type='email'
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 50 }}
                {...field}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
            <Controller
              control={control}
              defaultValue=''
              name='providerBoletaPhone'
              rules={{
                required: '(*) Campo obligatorio',
                validate: {
                  minValid: (fono) =>{
                    if (fono) {
                    return validateMinLength(fono.replace(/\D/g,''), 11) ||
                    'Teléfono no puede ser menor a 11 dígitos'
                    } else {
                      return
                    }},
                },
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <Input
                  value={maskTel(value) || ''}
                  isRequired
                  onChange={onChange}
                  label='Teléfono'
                  error={Boolean(error)}
                  helperText={error?.message}
                  inputProps={{ maxLength: 15 }}
                  {...rest}
                />
              )}
            />
          </Box>
      </Box>
    </Stack>
  )
}