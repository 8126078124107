import { 
  COURIER_EDIT,
  COURIER_ACTIVATE,
  COURIER_DELETE,
  COURIER_FILTER,
  COURIER_INFO,
  COURIER_LIST,
  COURIER_REPORT,
  PEONETA_EDIT,
  PEONETA_ACTIVATE,
  PEONETA_DELETE,
  PEONETA_FILTER,
  PEONETA_INFO,
  PEONETA_LIST,
  PEONETA_REPORT,
  WALKER_ACTIVATE,
  WALKER_DELETE,
  WALKER_EDIT,
  WALKER_FILTER,
  WALKER_INFO,
  WALKER_LIST,
  WALKER_REPORT,
} from "../../constants/functionalities";

const functionalityMap = {
  courier: {
    EDIT: COURIER_EDIT,
    ACTIVATE: COURIER_ACTIVATE,
    DELETE: COURIER_DELETE,
    FILTER: COURIER_FILTER,
    INFO: COURIER_INFO,
    LIST: COURIER_LIST,
    REPORT: COURIER_REPORT,
  },
  peoneta: {
    EDIT: PEONETA_EDIT,
    ACTIVATE: PEONETA_ACTIVATE,
    DELETE: PEONETA_DELETE,
    FILTER: PEONETA_FILTER,
    INFO: PEONETA_INFO,
    LIST: PEONETA_LIST,
    REPORT: PEONETA_REPORT,
  },
  walker: {
    EDIT: WALKER_EDIT,
    ACTIVATE: WALKER_ACTIVATE,
    DELETE: WALKER_DELETE,
    FILTER: WALKER_FILTER,
    INFO: WALKER_INFO,
    LIST: WALKER_LIST,
    REPORT: WALKER_REPORT,
  },
};

export const ACTION_EMPLOYEE = {
  EDIT: "EDIT",
  ACTIVATE: "ACTIVATE",
  DELETE: "DELETE",
  FILTER: "FILTER",
  INFO: "INFO",
  LIST: "LIST",
  REPORT: "REPORT",
}

export const getFuncionalityEmployee = (type, action) => {
  return functionalityMap[type]?.[action] || null;
};