import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { Input } from '../../../components/Inputs'
import { validateRut } from '../../../utils/validateRut'
import { maskRUT } from '../../../utils/masks'
import { isComplexTextValid, isSimpleNumberValid } from '../../../utils/validateValues'
import { useGetBankList } from '../../../v2/services/hooks/Setting/Bank/useGetBankList'
import { useState } from 'react'

export const BankSection = ({ control, isEditing }) => {
  const { data: banks, isLoading: isBanklistLoading } = useGetBankList()
  const [ accountType, setAccountType ] = useState([])

  return (
    <Stack spacing={2} sx={{mt:2}}>
      <Box
        display='grid'
        gridTemplateColumns='repeat(12, 1fr)'
        gap={6}
      >
        <Box gridColumn={{ xs: 'span 12', lg: 'span 12' }}>
          <Typography variant='h6' sx={{ fontWeight: 700 }}>
            Datos de cuenta de banco
          </Typography>
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            name='bankName'
            rules={{
              required: '(*) Campo obligatorio',
              validate: {
                textIsValid: (text) =>
                  isComplexTextValid(text) ||
                  'Nombre inválido, no se aceptan caracteres extraños ni dígitos',
              },
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={value || ''}
                disabled={isEditing}
                onChange={onChange}
                label='Nombre'
                fullWidth
                isRequired
                error={Boolean(error)}
                helperText={error?.message}
                {...rest}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            name='bankRut'
            rules={{
              required: '(*) Campo obligatorio',
              validate: {
                rutIsValid: (rut) =>
                  validateRut(maskRUT(rut)) || 'RUT inválido',
              },
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={value ? maskRUT(value) : ''}
                disabled={isEditing}
                onChange={(e) => {
                  e.target.value = maskRUT(e.target.value)
                  onChange(e)
                }}
                label='RUT'
                isRequired
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 12 }}
                {...rest}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            defaultValue=''
            name='bankEmail'
            rules={{
              required: '(*) Campo obligatorio',
              validate: (value) => {
                const validEmailRegex =
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                return validEmailRegex.test(value) || 'Email inválido'
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                label='Correo electrónico'
                fullWidth
                isRequired
                type='email'
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 50 }}
                {...field}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Stack spacing={2}>
            <Stack direction='row'>
              <Typography variant='body1' sx={{ '& span': { color: 'orange' }, marginBottom: "10px" }}>
                Banco <span>*</span>
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ minWidth: 160 }}>
            <Controller
              control={control}
              name='bank'
              rules={{
                required: '(*) Campo obligatorio',
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Select
                  labelId="blocked-label"
                  size="small"
                  sx={{ borderRadius: "15px", backgroundColor: "white" }}
                  value={value} 
                  onChange={(e) => {
                    onChange(e)
                    setAccountType(banks.data.find(bank => bank.name === e.target.value).accountType)
                  }}
                >
                  {!isBanklistLoading && banks.data.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Stack>
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Stack spacing={2}>
            <Stack direction='row'>
              <Typography variant='body1' sx={{ '& span': { color: 'orange' }, marginBottom: "10px" }}>
                Tipo de cuenta <span>*</span>
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ minWidth: 160 }}>
            <Controller
              control={control}
              name='bankType'
              rules={{
                required: '(*) Campo obligatorio',
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Select
                  labelId="blocked-label"
                  size="small"
                  sx={{ borderRadius: "15px", backgroundColor: "white" }}
                  value={value}
                  onChange={onChange}
                >
                  {accountType.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Stack>
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            name='bankAccount'
            rules={{
              required: '(*) Campo obligatorio',
              validate: {
                textIsValid: (text) =>
                  isSimpleNumberValid(text) ||
                  'Cuenta inválida, no se aceptan caracteres extraños',
              },
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={value || ''}
                onChange={onChange}
                disabled={isEditing}
                label='Número de cuenta'
                isRequired
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 55 }}
                {...rest}
              />
            )}
          />
        </Box>
      </Box>
    </Stack>
  )
}