import { Stack, Typography } from "@mui/material";
import { employeeType } from "../../../constants/employeeType";
import { MyEmployeesList } from "./components/MyEmployeesList";

export default function ProviderPeonetasList() {
  return (
    <Stack spacing={2}>
      <Typography variant="h4" align="center">
        Mis Peonetas
      </Typography>
      <MyEmployeesList myEmployeeType={employeeType.PEONETA} />
    </Stack>
  )
}
