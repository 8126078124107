/* eslint-disable react/jsx-no-duplicate-props */
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Container,
  InputAdornment,
  Stack,
  Switch,
  FormControlLabel,
  Typography,
  Button,
  Tooltip,
  Grid,
  Autocomplete,
} from "@mui/material";
import { ArrowBackOutlined, InfoOutlined } from "@mui/icons-material";

import { useListGeographies } from "../../../../services/hooks";
import { maskOnlyNumber } from "../../../../utils/masks";

import { PaperBlue } from "../../../../components/Papers";
import { BoxHeader } from "../BoxHeader";
import { Input } from "../../../../components/Inputs";
import { offerContractType } from "../../../../constants/contractType";

import {
  isComplexTextNumberValid,
  validateMinLength,
} from "../../../../utils/validateValues";
import { Editor } from "../../../../v2/components/Editor";

export function Step1({
  onNextStep,
  handleSubmit,
  control,
  setValue,
  allowedEdit,
  offer,
}) {
  const navigate = useNavigate();
  const [bases, setBases] = useState([]);

  const { data: listGeographies, isLoading: listGeographiesIsLoading } =
    useListGeographies();

  useEffect(() => {
    if (listGeographies) {
      setValue(
        "setting.city",
        listGeographies.find((geo) => {
          return geo?.name === offer?.setting?.city;
        })
      );
    }
  }, [listGeographies]);

  const setSelectedBase = (base) => {
    setValue("setting.base", base);
  };

  const onSubmit = () => {
    onNextStep();
  };

  const isBoleta = () => {
    if (!allowedEdit && offer?.contractType === offerContractType.BOLETA) {
      return true;
    }
  };

  return (
    <>
      <BoxHeader
        subTitle="Ingresa la información que describe  tu oferta de trabajo o actividad."
        content="Separamos cada solicitud en pasos simples. 
        Ante todo, elija la información principal que identifica  la oferta a los candidatos. "
      />

      <Container>
        <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <PaperBlue>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                ¿Cómo le gustaría llamar a su oferta de trabajo?
              </Typography>
              <Tooltip
                title="Describa tu oferta en los términos adecuados o más comúnmente utilizados para que el candidato la comprenda."
                placement="right"
                arrow
              >
                <InfoOutlined sx={{ fontSize: 16 }} />
              </Tooltip>
            </Stack>
            <Controller
              control={control}
              name="title"
              rules={{
                required: "(*) Campo obligatorio",
                validate: {
                  minValid: (text) =>
                    validateMinLength(text, 10) ||
                    "Nombre oferta no puede ser menor a 10 caracteres",
                  textIsValid: (text) =>
                    isComplexTextNumberValid(text.trim()) ||
                    "Nombre oferta inválido, no se aceptan caracteres extraños",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  error={Boolean(error)}
                  helperText={error?.message}
                  inputProps={{ maxLength: 100 }}
                  {...field}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "15px",
                  }}
                  disabled={!allowedEdit}
                />
              )}
            />
          </PaperBlue>

          <Stack spacing={2}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="commercialOffer.value"
                  render={({ field: { value, onChange, ...rest } }) => (
                    <Input
                      value={maskOnlyNumber(value)}
                      onChange={onChange}
                      label="Oferta comercial"
                      placeholder="1.000.000"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      {...rest}
                      disabled={!allowedEdit}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="setting.city"
                  rules={{ required: "(*) Campo obligatorio" }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      size="small"
                      options={listGeographies || []} // Default to an empty array
                      getOptionLabel={(option) =>
                        option?.name ? option.name : ""
                      }
                      renderInput={(params) => (
                        <Input
                          {...params}
                          placeholder="Elige una ciudad"
                          label="Ciudad"
                          error={Boolean(error)}
                          helperText={error?.message}
                          disabled={!allowedEdit}
                        />
                      )}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        setBases(newValue?.bases ?? []);
                        setValue("setting.base", null);
                      }}
                      value={value}
                      isLoading={listGeographiesIsLoading}
                      disabled={!allowedEdit}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="setting.base"
                  rules={{ required: "(*) Campo obligatorio" }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      size="small"
                      options={bases}
                      getOptionLabel={(option) =>
                        option?.name ? `${option.name} (${option?.code})` : ""
                      }
                      renderInput={(params) => (
                        <Input
                          {...params}
                          placeholder="Elige una base"
                          label="Base"
                          error={Boolean(error)}
                          helperText={error?.message}
                          disabled={!allowedEdit}
                        />
                      )}
                      onChange={(e, newValue) => {
                        setSelectedBase(newValue);
                        onChange(newValue);
                      }}
                      value={value}
                      disabled={!allowedEdit}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Stack direction="row" alignItems="center">
              <Controller
                control={control}
                name="commercialOffer.isPrivate"
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={value}
                        onChange={onChange}
                      />
                    }
                    label="Oferta comercial privada"
                    disabled={!allowedEdit}
                  />
                )}
              />
              <Tooltip
                title="Con el botón activo, el candidato solo será informado de la propuesta o paga en tu proceso de selección."
                placement="right"
                arrow
              >
                <InfoOutlined sx={{ fontSize: 16 }} />
              </Tooltip>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Controller
              control={control}
              name="contractType"
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={isBoleta(value)}
                      onChange={onChange}
                    />
                  }
                  label="Oferta para proveedores sin inicio de actividades SII (boleta)"
                  disabled={!allowedEdit}
                />
              )}
            />
          </Stack>

          <Stack spacing={2}>
            <Controller
              control={control}
              name="description"
              rules={{
                required: "(*) Campo obligatorio",
                validate: {
                  minValid: (text) =>
                    validateMinLength(text, 1) ||
                    "Decripción no puede ser menor a 1 caracter",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div>
                  <div>
                    <p>
                      Describa el puesto o actividad de la oferta <span>*</span>
                    </p>
                  </div>
                  <Editor
                    initialState={value}
                    placeholder="Ingresa una descripción de la oferta"
                    onChange={onChange}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="details"
              rules={{
                required: "(*) Campo obligatorio",
                validate: {
                  minValid: (text) =>
                    validateMinLength(text, 1) ||
                    "Detalle no puede ser menor a 1 caracter",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div>
                  <div>
                    <p style={{ "& span": { color: "orange" } }}>
                      Detalles de la oferta <span>*</span>
                    </p>
                  </div>
                  <Editor
                    initialState={value}
                    placeholder="Inserta los detalles"
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </Stack>

          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              variant="goBack"
              disableRipple
              startIcon={<ArrowBackOutlined />}
              onClick={() => navigate(-1)}
            >
              Volver atrás
            </Button>
            <Button size="large" type="submit">
              Siguiente
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
