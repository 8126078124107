import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import { Typography } from '@mui/material';
import { ReactComponent as RemoveIcon } from "../../assets/images/remove.svg"
import { ReactComponent as AddIcon } from "../../assets/images/add.svg"

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  background:"#E5E5E5",
  borderRadius:"10px",
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    backgroundColor:"#E5E5E5",
    color:"#808080",
    borderRadius: "10px",
    [`&:hover`]:{
      backgroundColor:"#CECECE",
      borderRadius: "10px",
    },
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
      borderRadius: "10px",
    },
    [`&.${toggleButtonGroupClasses.selected}`]: {
      border: 0,
      borderRadius:"10px",
      backgroundColor:"#0032A0",
      color:"#FFFFFF",
      [`&:hover`]:{
        backgroundColor:"#0032A0",
      },
    },
  },
}));


export default function SwitchButton({
  switchLeft,
  setSwitchLeft,
  titleLeft,
  titleRight,
}) {
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setSwitchLeft(newAlignment === 'left'  ? true : false);
    }
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={switchLeft ? 'left' : 'right'}
          exclusive
          onChange={handleAlignment}
        >
          <ToggleButton value="left"  sx={{width:"260px", height:"40px"}}>
            {switchLeft ? <AddIcon/> : <RemoveIcon/>}
            <Typography
              fontFamily="Lato"
              fontWeight={700}
              fontSize="14px"
              lineHeight="16.8px"
              sx={{ml:1}}
            >
             
              {titleLeft}
            </Typography>
          </ToggleButton>
          <ToggleButton value="right"  sx={{width:"260px", height:"40px"}}>
            {switchLeft ? <RemoveIcon /> : <AddIcon/>}
            <Typography
              fontFamily="Lato"
              fontWeight={700}
              fontSize="14px"
              lineHeight="16.8px"
              sx={{ml:1}}
            >
              {titleRight}
            </Typography>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
}