import { useQuery } from 'react-query'
import { BANK_URI } from '../../../../../constants/api';
import { useApi } from '../../../../../services/api';

export async function getBankList() {
  const { axiosInstance } = useApi();
  const response = await axiosInstance.get(`${BANK_URI}/`);
  
  return response.data;
}

export function useGetBankList() {
  return useQuery(["getListBanks"], () => getBankList());
}