import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "@blue-express/bx-lib-universal-frontend";
import { Table } from "../../../components/Table";
import { SearchBar } from "../../../../components/SearchBar";
import { useMyProvider } from "../../../../services/hooks/Providers/useMyProvider";
import { useListEmployeesByTypeByProvider } from "../../../services/hooks/Provider/useListEmployeesByTypeByProvider";
import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { MessageBox } from "../../../../components/common/MessageBox";
import { EmployeeStatus } from "../../../../constants/status";
import { headCellsProviderEmployeeList } from "../../../helper/headcell";
import { ChipStatus } from "../../../components/Chip";
import { ReactComponent as EditButtonIcon } from "../../../assets/images/editbutton.svg";
import { ReactComponent as InfoButton } from "../../../assets/images/infobutton.svg";
import { ReactComponent as ReplaceButton } from "../../../assets/images/replacebutton.svg";
import { ReplaceEmployee } from "../components/ReplaceEmployee";
import { TableButton } from "../../../components/Button/TableButton";
import ListDocumentsModal from "../../../components/Modal/ListDocumentModal";
import { vacancyType } from "../../../../constants/vacancyType";
import { replaceDataAtom, replaceEmployeeDataAtom } from "../../../store/requestTracker/replaceRequest.store";
import { useAtom } from "jotai";
import { useURLfilters } from "../../../hooks/filters.hook";
import { employeeType } from "../../../../constants/employeeType";

const initialValue = {
  rut: "",
  name: "",
  lastname1: "",
  lastname2: "",
  phone: "",
  email: "",
};

export const MyEmployeesList = ({ myEmployeeType }) => {
  const defaultFilters = {
    type: myEmployeeType,
    isProviderList: true,
  };

  const navigate = useNavigate();
  const { forceRefreshSession, getSession } = useSession();
  const session = getSession();
  const providerEmail = session?.username;
  const { data: myProvider, isLoading: providerLoading } =
    useMyProvider(providerEmail);
  const { filters, setFilters, cleanFilters, getURLValue } = useURLfilters(
    defaultFilters,
    {
      excludeParams: ["providerId"],
      extraParams: {
        providerId: myProvider?.id,
      },
    }
  );
  const [currentItem, setCurrentItem] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState();
  const [isRenderedReplace, setIsRenderedReplace] = useState(false);
  const [, setReplaceData] = useAtom(replaceDataAtom);
  const [, setEmployeeData] = useAtom(replaceEmployeeDataAtom);
  const {
    data: { data, metadata } = {},
    isLoading,
    error,
  } = useListEmployeesByTypeByProvider(filters);

  const { TableContainer } = Table(
    headCellsProviderEmployeeList,
    {
      ...metadata,
      page: getURLValue("page", 1),
      limit: getURLValue("limit", 10),
    },
    onChangeFilters
  );

  const handleSearch = (event) => {
    const { value } = event.target;
    setFilters({ search: value, page: 1 });
  };

  function onChangeFilters(newFilters = {}) {
    setFilters(newFilters);
  }

  useEffect(() => {
    forceRefreshSession();
    setReplaceData({});
    setEmployeeData(initialValue);
  }, []);

  const onEditDocument = (item) => {
    navigate(`/provider/employee/edit/${item.id}`);
  };

  const onReplaceEmployee = (item) => {
    setCurrentItem(item);
    setIsRenderedReplace(true);
  };

  const handleGoBack = () => {
    setReplaceData({});
    setEmployeeData(initialValue);
    setIsRenderedReplace(false);
  };

  if (isRenderedReplace) {
    return <ReplaceEmployee goBack={handleGoBack} item={currentItem} />;
  }

  if (error) return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading || providerLoading) return <LoadingSpinner open />;

  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <SearchBar
        id="table-searchbar"
        type="text"
        placeholder="Buscar"
        onSearch={handleSearch}
        search={getURLValue("search", "")}
      />
      <Button
        variant="contained"
        onClick={cleanFilters}
        size="medium"
        sx={{ marginLeft: 3 }}
      >
        Limpiar filtros
      </Button>
    </Stack>
  );

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <TableContainer addons={[TabsAddon]} withPagination>
        <TableBody>
          {data?.map((item) => (
            <TableRow hover tabIndex={-1} key={item.id}>
              <TableCell>
                <ChipStatus
                  label={EmployeeStatus[item.status]}
                  setcolor={item.status}
                />
              </TableCell>
              <TableCell>
                {item.name} {item.lastname1} {item?.lastname2 ?? ""}
              </TableCell>
              <TableCell>{item.numberRut}</TableCell>
              <TableCell>{item?.active?.isActive ? 'Activo' : 'Inactivo'}</TableCell>
              <TableCell>
                <TableButton
                  onClick={() => onEditDocument(item)}
                >
                  <EditButtonIcon />
                </TableButton>
                <TableButton
                  onClick={() => {
                    setItem(item);
                    setShowModal(true);
                  }}
                >
                  <InfoButton />
                </TableButton>
                {
                  !(myEmployeeType === employeeType.WALKER) &&
                  <TableButton
                    disabled={!!item?.requested}
                    onClick={() => onReplaceEmployee(item)}
                  >
                    <ReplaceButton />
                  </TableButton>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
      <ListDocumentsModal
        isOpen={showModal}
        onClose={handleCloseModal}
        type={vacancyType.EMPLOYEE}
        item={item}
      />
    </>
  );
}
