import { useQuery } from "react-query";
import { useApi } from "../../../../services/api";
import { APPLY_URI, EMPLOYEE_URI } from "../../../../constants/api";
import { buildUrlWithQueryParams } from "../../../helper/converters";

export async function getListSecurity(filters) {
  const { axiosInstance } = useApi();
  const baseUrl =
    filters?.type === "provider" ? EMPLOYEE_URI : `${APPLY_URI}/by-security`;

  const url = buildUrlWithQueryParams(baseUrl, filters);

  const response = await axiosInstance.get(url);
  const { data, metadata } = response.data;

  return { data, metadata };
}

export function useListSecurity(filters) {
  return useQuery(["listSecurity", filters], () => getListSecurity(filters));
}
