import { useSession } from "@blue-express/bx-lib-universal-frontend";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useMyProvider } from "../../services/hooks/Providers/useMyProvider";
import { useOfferDetails } from "../../services/hooks/Offers/useOfferDetails";
import { useEffect, useState } from "react";
import { useCreateApply } from "../../services/hooks/Apply";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Alert } from "../../components/Alert";
import { useQueryClient } from "react-query";
import { STATUS_CODE } from "../../constants/status";
import useGetApplyByProviderAndOffer from "../../v2/services/hooks/apply/useGetApplyByProviderAndOffer";

const initialAlertError = {
  open: false,
  title: "",
  messages: [],
  content: "",
  isOfferError: false,
  isContractTypeError: false,
};

export function OfferApply() {
  const navigate = useNavigate();
  const { mutate } = useCreateApply();
  const queryClient = useQueryClient();

  const [alertError, setAlertError] = useState(initialAlertError);

  const [searchParams] = useSearchParams();
  const { getSession, logout } = useSession();

  const idOffer = searchParams.get("offer");
  const { data: offerDetails, isLoading } = useOfferDetails(idOffer);
  const session = getSession();
  const providerEmail = session?.username;
  const { data: provider, isLoading: providerLoading } =
    useMyProvider(providerEmail);
  const {
    data: apply,
    refetch: getApply,
    isLoading: applyIsLoading,
  } = useGetApplyByProviderAndOffer(provider?.id, idOffer);

  const handleConfirmation = () => {
    setAlertError({
      ...alertError,
      open: false,
    });
    if (alertError.isOfferError){
      navigate("/myrequest");
    }
    if (alertError.isContractTypeError){
      logout();
    }
  };

  const createApply = () => {
    const newApply = {
      providerId: provider.id,
      offer: offerDetails,
    };

    mutate(newApply, {
      onSuccess: (createdApply) => {
        queryClient.invalidateQueries(["listApply"]);
        navigate(`/application/${createdApply.data.id}/fleet/1`);
      },
      onError: ({ response }) => {
        if (response?.status === STATUS_CODE.CONFLICT) {
          getApply();
        } else {
          setAlertError({
            ...alertError,
            open: true,
            title: "Error",
            isOfferError: true,
            messages: [
              "Oh no! se ha producido un error al crear la postulación",
            ],
          });
        }
      },
    });
  };

  useEffect(() => {
    if (apply) navigate(`/application/${apply.id}/fleet/1`);
  }, [apply]);

  useEffect(() => {
    if (!offerDetails && !isLoading) {
      setAlertError({
        ...alertError,
        open: true,
        isOfferError: true,
        title: "Error",
        messages: ["Oh no! se ha producido un error al obtener la oferta"],
      });
      navigate(`/myrequest`);
    }
    if (
      !isLoading 
      && !!offerDetails?.contractType
      && !!provider?.contractType
      && offerDetails.contractType !== provider?.contractType
    ) {
      setAlertError({
        ...alertError,
        open: true,
        isOfferError: false,
        isContractTypeError: true,
        title: "Error",
        messages: [
          "Lo sentimos, está intentando acceder a una oferta que no es compatible con su tipo de contrato.",
        ],
      });
      return;
    }

    if (offerDetails && provider) {
      createApply();
    }
  }, [offerDetails, provider, applyIsLoading, isLoading]);

  return (
    <>
      {(isLoading || providerLoading) && <LoadingSpinner open />}
      <Alert
        icon
        open={alertError.open}
        handleConfirmation={handleConfirmation}
        title={alertError.title}
        messages={alertError.messages}
      />
    </>
  );
}
