import { Stack, Typography } from "@mui/material";
import { employeeType } from "../../../constants/employeeType";
import { MyEmployeesList } from "./components/MyEmployeesList";

export default function ProviderCouriersList() {
  return (
    <Stack spacing={2}>
      <Typography variant="h4" align="center">
        Mis Conductores
      </Typography>
      <MyEmployeesList myEmployeeType={employeeType.COURIER} />
    </Stack>
  );
}
