import { useEffect, useState } from "react";
import { InfoOutlined } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useListEmployee } from "../../services/hooks/Employee/useListEmployee";
import { Table } from "../../components/Table";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { ActiveStatus, EmployeeStatus } from "../../../constants/status";
import ListDocumentsModal from "../../components/Modal/ListDocumentModal";
import { vacancyType } from "../../../constants/vacancyType";
import { employeeType } from "../../../constants/employeeType";
import { headCellsProviderBackOffice } from "../../helper/headcell";
import { TabAddonsList } from "../../components/TabsAddon";
import { useURLfilters } from "../../hooks/filters.hook";
import { useReportProvider } from "../../services/hooks/Employee/useReportProviders";
import { urlDownload } from "../../helper/files";
import { AlertSnackBar } from "../../../components/Alerts";
import { formatDateApprovalFlow } from "../../../utils/formatValues";
import { ProtectedComponent } from "../../../components/ProtectedComponent";
import { PROVIDER_INFO } from "../../../constants/functionalities";

const defaultFilters = {
  type: employeeType.PROVIDER,
  status: EmployeeStatus.APPROVED,
  page: 1,
  limit: 10,
};

export default function ProviderList() {
  const [item, setItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [error, setError] = useState({ isOpen: false });
  const { mutate: mutateReportProviders, isLoading: isLoadingReport } =
    useReportProvider();

  const { filters, setFilters, cleanFilters, getURLValue } =
    useURLfilters(defaultFilters);
  const { data, isLoading } = useListEmployee(filters);

  const onChangeFilters = (newFilter = {}) => {
    setFilters({ ...filters, ...newFilter });
  };

  const { TableContainer, setTableLoading, setPage } = Table(
    headCellsProviderBackOffice,
    {
      ...data?.metadata,
      page: getURLValue("page", 1),
      limit: getURLValue("limit", 10),
    },
    onChangeFilters
  );

  useEffect(() => {
    setTableLoading(isLoading);
    if (data) {
      setDownloadData(
        data.data.map((item) => {
          return {
            numberRut: item.numberRut,
            entryDate: item?.entryDate ?? "-",
            name: `${item.name} ${item?.lastname1 ?? ""}`.trim(),
            numberRutLegalRepresentative: item.legalRepresentative?.dni,
            nameLegalRepresentative: `${item.legalRepresentative?.name} ${
              item.legalRepresentative?.lastname1 ?? ""
            } ${item.legalRepresentative?.lastname2 ?? ""
            }`.trim(),
            email: item.email,
            phone: item.phone,
            address: item.address?.street
              .concat(" ")
              .concat(item.address.number),
            commune: item.address?.commune,
            active: item?.active?.isActive
              ? ActiveStatus.ACTIVE
              : ActiveStatus.INACTIVE,
            blocked: item.blocked.isBlocked ? "SI" : "NO",
          };
        })
      );
    }
  }, [data, isLoading]);

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    setFilters({ search: newValue, page: 1 });
    setPage(0);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onChangeBlocked = (event) => {
    onChangeFilters({ isBlocked: event.target.value, page: 1 });
  };

  const onChangeActive = (event) => {
    onChangeFilters({ isActive: event.target.value, page: 1 });
  };

  const handleDownloadReport = () => {
    mutateReportProviders(filters, {
      onSuccess: (filePath) => {
        urlDownload(filePath);
      },
      onError: () => {
        setError({
          isOpen: true,
          title: "Error",
          message:
            "Hubo un error al descargar el reporte de flotas. Inténtelo nuevamente.",
          handleClose: () => setError({ isOpen: false }),
        });
      },
    });
  };

  const TabsAddons = () => (
    <TabAddonsList
      search={getURLValue("search", "")}
      clearFilters={cleanFilters}
      active={getURLValue("isActive", "")}
      blocked={getURLValue("isBlocked", false)}
      downloadData={downloadData}
      handleSearch={handleSearch}
      onChangeActive={onChangeActive}
      onChangeBlocked={onChangeBlocked}
      headCell={headCellsProviderBackOffice}
      nameFile={employeeType.PROVIDER}
      handleDownloadReport={handleDownloadReport}
    />
  );

  if (isLoading || isLoadingReport) return <LoadingSpinner open />;

  return (
    <Stack spacing={2}>
      <TableContainer addons={[TabsAddons]} withPagination>
        <TableBody>
          {data?.data.map((employee) => (
            <TableRow hover tabIndex={-1} key={employee.id}>
              <TableCell>{employee.numberRut}</TableCell>
              <TableCell>
                {employee?.entryDate
                  ? formatDateApprovalFlow(employee.entryDate)
                  : "-"}
              </TableCell>
              <TableCell>{employee.name}</TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {employee.legalRepresentative?.dni}
              </TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {`${employee.legalRepresentative?.name} ${
                  employee.legalRepresentative?.lastname1 ?? ""
                } ${employee.legalRepresentative?.lastname2 ?? ""
                }`.trim()}
              </TableCell>
              <TableCell>{employee.email}</TableCell>
              <TableCell>{employee.phone}</TableCell>
              <TableCell>
                {employee.address?.street
                  .concat(" ")
                  .concat(employee.address.number)}
              </TableCell>
              <TableCell>{employee.address?.commune}</TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {employee?.active?.isActive
                  ? ActiveStatus.ACTIVE
                  : ActiveStatus.INACTIVE}
              </TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {employee.blocked.isBlocked ? "SI" : "NO"}
              </TableCell>
              <TableCell>
                <Stack direction="row">
                  <ProtectedComponent functionality={[PROVIDER_INFO]}>
                    <IconButton
                      onClick={() => {
                        setItem(employee);
                        setShowModal(true);
                      }}
                    >
                      <InfoOutlined />
                    </IconButton>
                  </ProtectedComponent>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
      <AlertSnackBar
        open={error.isOpen}
        message={error.message}
        handleClose={error.handleClose}
      />
      <ListDocumentsModal
        isOpen={showModal}
        onClose={handleCloseModal}
        type={vacancyType.EMPLOYEE}
        item={item}
      />
    </Stack>
  );
}
