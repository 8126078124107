import {
  Box,
  Stack,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { Input } from '../../Inputs'
import { validateRut } from '../../../utils/validateRut'
import { maskRUT } from '../../../utils/masks'
import {
  isComplexTextSpecialValid,
} from '../../../utils/validateValues'
import { useSession } from '@blue-express/bx-lib-universal-frontend'

export const EmployeeSection = ({ control, getValue }) => {
  const { getSession } = useSession()
  const session = getSession()
  return (
    <Stack spacing={2} sx={{mt:2}}>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={6} >
          <Box gridColumn={{ xs: 'span 12', lg: 'span 12' }}>
            <Typography variant='h6' sx={{ fontWeight: 700 }}>
              Datos del proveedor
            </Typography>
          </Box>
          <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
            <Controller
              control={control}
              name='providerRut'
              rules={{
                required: '(*) Campo obligatorio',
                validate: {
                  rutIsValid: (rut) =>
                    validateRut(maskRUT(rut)) || 'RUT inválido',
                },
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <Input
                  value={maskRUT(value) || ''}
                  onChange={(e) => {
                    e.target.value = maskRUT(e.target.value)
                    onChange(e)
                  }}
                  label='RUT'
                  isRequired
                  error={Boolean(error)}
                  helperText={error?.message}
                  inputProps={{ maxLength: 12 }}
                  {...rest}
                />
              )}
            />
          </Box>
          <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
            <Controller
              control={control}
              name='providerName'
              rules={{
                required: '(*) Campo obligatorio',
                validate: {
                  textIsValid: (text) =>
                    isComplexTextSpecialValid(text) ||
                    'Nombre inválido, no se aceptan caracteres extraños ni dígitos',
                },
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <Input
                  value={value || ''}
                  onChange={onChange}
                  label='Razón Social'
                  isRequired
                  error={Boolean(error)}
                  helperText={error?.message}
                  {...rest}
                />
              )}
            />
          </Box>
          <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
            <Controller
              control={control}
              defaultValue=''
              name='providerEmail'
              rules={{ 
                required: '(*) Campo obligatorio',
                validate: (value) => {
                  const validEmailRegex =
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                  return validEmailRegex.test(value) || 'Email inválido'
                }, 
              }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  disabled={Boolean(session?.username)}
                  label='Correo electrónico'
                  fullWidth
                  isRequired
                  type='email'
                  error={Boolean(error)}
                  helperText={error?.message}
                  inputProps={{ maxLength: 50 }}
                  {...field}
                />
              )}
            />
          </Box>
        </Box>
    </Stack>
  )
}