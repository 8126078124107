import { contextMessageDictionary } from "../../../constants/contextMessageDictionary";
import { useGetContextMessages } from "../../services/hooks/ContextMessages/useGetContextMessages";
import { ContextMessageContext } from "./contextMessage.context";

export const ContextMessageProvider = ({ children }) => {
  const { data: contextMessages, isLoading } = useGetContextMessages();
  const securityConditions = contextMessages?.data.find((message) => message.name === contextMessageDictionary.SECURITY_CONDITIONS);
  const termsAndConditions = contextMessages?.data.find((message) => message.name === contextMessageDictionary.TERMS_AND_CONDITIONS);
  const privacyPolicies = contextMessages?.data.find((message) => message.name === contextMessageDictionary.PRIVACY_POLICIES);
  return (
    <ContextMessageContext.Provider
      value={
        {
          contextMessages,
          securityConditions,
          termsAndConditions,
          privacyPolicies,
          isLoading
        }}
    >
      {children}
    </ContextMessageContext.Provider>
  );
}
