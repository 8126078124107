export const contractType = {
  LABORAL: "laboral",
  COMERCIAL: "comercial",
};

export const offerContractType = {
  BOLETA: "boleta",
  FACTURA: "factura",
};

export const labelOfferContractType = {
  [offerContractType.BOLETA]: "Boleta",
  [offerContractType.FACTURA]: "Factura",
};
