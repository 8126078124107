import { Stack, Typography, Box } from "@mui/material";
import { WarningIcon } from "../../Icons/WarningIcon";
import { GroupedDocuments } from "./GroupedDocuments";

export const DocumentsSection = ({ control, documentStructure }) => {
  return (
    <Stack sx={{ mb: 4 }}>
      <Box gridColumn={{ xs: "span 12", lg: "span 12" }} sx={{ mb: 4, mt: 4 }}>
        <Typography
          variant="h6"
          fontFamily="Lato"
          fontWeight="800px"
          fontSize="20px"
          lineHeight="27px"
        >
          Documentos
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: "#F6F6F6",
          borderRadius: "10px",
          border: "solid 0.6px #CCCCCC",
          borderColor: "#CCCCCC",
          mb: 4,
        }}
      >
        <Stack sx={{ mx: 2, mt: 2, mb: 2, flexDirection: "row" }}>
          <WarningIcon
            sx={{ alignSelf: "center", mr: 2, width: "32px", height: "32px" }}
          />
          <Stack>
            <Typography
              fontFamily="Lato"
              variant="body1"
              fontWeight={400}
              lineHeight="18.9px"
              fontSize="14px"
              color="#0032A0"
              sx={{ "& span": { color: "orange" } }}
            >
              <span>Consejo: </span>
              Sube los archivos solicitados en formato jpeg, png, pdf o docx
              (Word). Los archivos no deben pesar más de 5 MB y deben ser
              legibles.
            </Typography>
          </Stack>
        </Stack>
      </Box>
      {documentStructure?.map((documentItem) => (
        <GroupedDocuments
          key={documentItem.id}
          control={control}
          groupedDocuments={documentItem}
        />
      ))}
    </Stack>
  );
};
