import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MessageBox } from "../../../components/common/MessageBox";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import {
  useApprobationDocuments,
  useGetDocuments,
} from "../../../services/hooks/Documents";
import {
  Box,
  Button,
  Stack,
} from "@mui/material";

import { ArrowBackOutlined } from "@mui/icons-material";
import { Dialogs } from "../../../components/Dialogs";
import {
  EmployeeStatus,
  MESSAGE_STATUS_MAPPING,
  VACANCY_TYPE_ENUM,
} from "../../../constants/status";
import { employeeType } from "../../../constants/employeeType";
import { useGetTemplate } from "../../../services/hooks/Docs/Templates/useGetTemplates";
import { templateNames } from "../../../constants/templateNames";
import { useOfferDetails } from "../../../services/hooks/Offers/useOfferDetails";
import { EntityCard } from "../EntityCard";
import { maskRUT } from "../../../utils";
import { vacancyType } from "../../../constants/vacancyType";
import { Alert } from "../../../components/Alert";
import wowIcon from "../../../assets/images/wow.png";
import { useQueryClient } from "react-query";
import { useRejectVacancy } from "../../services/hooks/vacancy/useRejectVacancy";
import { DocumentTable } from "./DocumentTable";
import { offerContractType } from "../../../constants/contractType";

const initialAlertError = {
  title: "",
  open: false,
  messages: [],
  icon: "",
};

export function DocumentList({ goBack, dataRecord }) {
  const { data, isLoading, error } = useGetDocuments(
    dataRecord?.type === employeeType.PROVIDER
      ? VACANCY_TYPE_ENUM.EMPLOYEE
      : dataRecord.vacancy.type,
    dataRecord?.type === employeeType.PROVIDER
      ? dataRecord.id
      : dataRecord.vacancy.id
  );
  const [alert, setAlert] = useState(initialAlertError);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogButton, setOpenDialogButton] = useState(false);
  const [action, setAction] = useState("");
  const [dataConfirmation, setDataConfirmation] = useState([]);
  const [documentSelect, setDocumentSelect] = useState();
  const [dataDocument, setDataDocument] = useState([]);
  const [buttonSelect, setButtonSelect] = useState("");
  const { mutate, isLoading: isLoadingDocuments } = useApprobationDocuments();
  const { mutate: rejectVehicle, isLoading: isLoadingRejectVehicle } =
    useRejectVacancy();

  const { data: dataDocumentTemplate } = useGetTemplate(dataRecord?.vacancy ?  dataRecord?.vacancy?.documentTemplateId :
    dataRecord.contractType === offerContractType.BOLETA ? templateNames.providerBoleta : templateNames.provider);
  const { data: dataOffer } = useOfferDetails(
    dataRecord.offerId && dataRecord.offerId
  );
  const queryClient = useQueryClient();
  const [showRejectVehicle, setShowRejectVehicle] = useState(false);

  const detailInfo = useMemo(() => {
    if (dataRecord?.type === employeeType.PROVIDER) {
      return [
        [
          "Datos del proveedor:",
          {
            "Nombre completo:": (
              <b>{`${dataRecord?.name} ${dataRecord?.lastname1} ${
                dataRecord?.lastname2 ?? ""
              }`}</b>
            ),
            "RUT:": <b>{maskRUT(dataRecord?.numberRut)}</b>,
            "Teléfono:": <b>{dataRecord?.phone}</b>,
            "E-mail:": <b>{dataRecord?.email}</b>,
          },
        ],
        [
          "Representante Legal:",
          {
            "Nombre:": <b>{dataRecord?.legalRepresentative?.name}</b>,
            "RUT:": <b>{maskRUT(dataRecord?.legalRepresentative?.dni)}</b>,
            "E-mail:": <b>{dataRecord?.legalRepresentative?.email}</b>,
          },
        ],
      ];
    }

    if (dataRecord?.vacancy?.type === vacancyType.EMPLOYEE) {
      const isPeoneta =
        dataRecord?.vacancy?.employeeType === employeeType.PEONETA;
      const employeeTitle = isPeoneta ? "Peoneta" : "Conductor";
      const employeeComplement = isPeoneta ? "Conductor" : "Peoneta";
      return [
        [
          `Datos del ${employeeTitle}:`,
          {
            "Nombre completo:": (
              <b>{`${dataRecord?.vacancy?.name} ${
                dataRecord?.vacancy?.lastname1
              } ${dataRecord?.vacancy?.lastname2 ?? ""}`}</b>
            ),
            "RUT:": (
              <b>
                {maskRUT(
                  isPeoneta
                    ? dataRecord?.vacancy?.peonetaDni
                    : dataRecord?.vacancy?.courierDni
                )}
              </b>
            ),
            "Teléfono:": <b>{dataRecord?.vacancy?.phone}</b>,
            "E-mail:": <b>{dataRecord?.vacancy?.email}</b>,
          },
        ],
        [
          `Datos del ${employeeComplement}:`,
          dataRecord?.vacancy?.peonetaDni ? 
          {
            [`Rut ${employeeComplement}:`]: (
              <b>
                {maskRUT(
                  isPeoneta
                    ? dataRecord?.vacancy?.courierDni
                    : dataRecord?.vacancy?.peonetaDni
                )}
              </b>
            ),
          } : 
          {
            [`Rut ${employeeComplement}:`] : 'El conductor no tiene peoneta'
          },
        ],
      ];
    }

    return [
      [
        "Datos del Vehículo:",
        {
          "Patente:": <b>{dataRecord?.vacancy?.plate}</b>,
          "Marca:": <b>{dataRecord?.vacancy?.brand}</b>,
          "Modelo:": <b>{dataRecord?.vacancy?.model}</b>,
          "Tipo:": <b>{dataRecord?.vacancy?.fleetType}</b>,
        },
      ],
      [
        "Datos del Conductor",
        {
          "RUT Conductor:": <b>{maskRUT(dataRecord?.vacancy?.courierDni)}</b>,
        },
      ],
    ].concat(
      !dataRecord?.vacancy?.peonetaDni
        ? []
        : [
            [
              "Datos del peoneta",
              {
                "RUT Peoneta:": (
                  <b>{maskRUT(dataRecord?.vacancy?.peonetaDni)}</b>
                ),
              },
            ],
          ]
    );
  }, [dataRecord]);

  useEffect(() => {
    if (data) {
      setDataDocument(data);
      const initialData = data
        .filter(
          (document) =>
            document.status === EmployeeStatus.APPROVED ||
            document.status === EmployeeStatus.REJECTED ||
            document.status === MESSAGE_STATUS_MAPPING.APPROVE ||
            document.status === MESSAGE_STATUS_MAPPING.REJECT ||
            document.action === MESSAGE_STATUS_MAPPING.APPROVE ||
            document.action === MESSAGE_STATUS_MAPPING.REJECT
        )
        .map((document) => ({ id: document.id, action: document.status }));
      setDataConfirmation(initialData);
    }
  }, [data]);

  const handleConfirmationDocument = (action, document) => {
    setDataConfirmation((prev) => {
      if (prev.some((item) => item.id === document.id)) {
        const dataChange = prev.map((item) =>
          item.id === document.id ? { id: item.id, action } : item
        );
        return dataChange;
      }
      return [...prev, { id: document.id, action }];
    });
    const dataStatusChange = dataDocument.map((item) => {
      if (item.id === document.id) {
        item.action = action;
      }
      return item;
    });
    setDataDocument(dataStatusChange);
    setOpenDialog(false);
  };

  const handleCancelDocument = () => {
    setDataConfirmation([]);
    const dataCancel = dataDocument.map((item) => {
      item.status = EmployeeStatus.PENDING;
      return item;
    });
    setDataDocument(dataCancel);
    setOpenDialogButton(false);
    goBack();
  };

  const handleConfirmationAlert = () => {
    goBack();
    setAlert({
      ...alert,
      open: false,
    });
  };


  const handleSend = () => {
    const vacancies = dataOffer?.vacancy ?? dataOffer?.setting?.vacancies ?? [];

    const documents = dataConfirmation.map((document) => ({
      id: document.id,
      action: document.action,
    }));

    const documentTemplateId =
      dataDocumentTemplate && dataDocumentTemplate.id
        ? dataDocumentTemplate.id
        : vacancies.find(
            (vacancy) => (vacancy.description === dataRecord.vacancy.fleetType || vacancy.vehicle.name === dataRecord.vacancy.fleetType)
          )["documentTemplateId"];
    const applyId = dataRecord?.applyId;
    mutate(
      { documents, documentTemplateId, applyId },
      {
        onSuccess: () => {
          setOpenDialogButton(false);
          setAlert({
            ...alert,
            open: true,
            title: "Felicidades",
            buttonClose: false,
            icon: (
              <Box
                component="img"
                alt="icon"
                src={wowIcon}
                sx={{
                  display: { xs: "none", lg: "block" },
                  width: "75px",
                  height: "75px",
                }}
              />
            ),
            messages: ["Aprobación de documentos se realizó correctamente"],
          });
          queryClient.refetchQueries("listInterested");
        },
      },
      {
        onError: () =>
          setAlert({
            ...alert,
            open: true,
            title: "Error",
            messages: ["Oh no! se ha producido un error al aprobar documentos"],
          }),
      }
    );
  };

  const toggleRejectVehicle = useCallback(() => {
    setShowRejectVehicle((prevState) => !prevState);
  }, []);

  const onRejectVehicle = useCallback(() => {
    rejectVehicle(
      {
        applyID: dataRecord.applyId,
        vacancyID: dataRecord.vacancy.id,
      },
      {
        onSuccess: () => {
          toggleRejectVehicle();
          goBack();
        },
        onError: () => {
          toggleRejectVehicle();
          setAlert({
            ...alert,
            open: true,
            title: "Error",
            messages: ["Oh no! se ha producido un error al rechazar vehículo"],
          });
        }
      }
    );
  }, [rejectVehicle]);

  if (error) return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading) return <LoadingSpinner open />;

  return (
    <Stack spacing={4}>
      <EntityCard entries={detailInfo} />
      <DocumentTable
        dataDocument={dataDocument}
        onThumbUp={(doc) => {
          setAction(MESSAGE_STATUS_MAPPING.APPROVE);
          setOpenDialog(true);
          setDocumentSelect(doc);
        }}
        onThumbDown={(doc) => {
          setAction(MESSAGE_STATUS_MAPPING.REJECT);
          setOpenDialog(true);
          setDocumentSelect(doc);
        }}
        data={data}
      />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ mt: 5 }}
      >
        <Button
          variant="goBack"
          disableRipple
          startIcon={<ArrowBackOutlined />}
          onClick={goBack}
          sx={{ height: 40, fontSize: 12, alignSelf: "self-start" }}
        >
          {"Volver atrás"}
        </Button>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            color="secondary"
            type="submit"
            sx={{ minWidth: "155px", height: 40 }}
            onClick={() => {
              setButtonSelect(MESSAGE_STATUS_MAPPING.cancel);
              setOpenDialogButton(true);
            }}
          >
            Cancelar
          </Button>

          {dataRecord?.vacancy?.type === vacancyType.FLEET && (
            <Button
              color="secondary"
              type="submit"
              sx={{ minWidth: "155px", height: 40 }}
              onClick={toggleRejectVehicle}
            >
              Vehículo no cumple
            </Button>
          )}

          <Button
            color="secondary"
            disabled={dataConfirmation?.length !== data?.length}
            type="submit"
            sx={{ minWidth: "155px", height: 40 }}
            onClick={() => {
              setButtonSelect(MESSAGE_STATUS_MAPPING.confirm);
              setOpenDialogButton(true);
            }}
          >
            Confirmar revisión
          </Button>
        </Stack>
      </Stack>
      <Alert
        open={alert?.open}
        handleConfirmation={handleConfirmationAlert}
        buttonClose={alert?.buttonClose}
        icon={alert?.icon}
        title={alert?.title}
        messages={alert?.messages}
      />
      <Dialogs
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirmation={() =>
          handleConfirmationDocument(action, documentSelect)
        }
        title={`¿Estás seguro de que quieres ${MESSAGE_STATUS_MAPPING[action]} este documento?`}
      />
      <Dialogs
        open={openDialogButton}
        handleClose={() => setOpenDialogButton(false)}
        handleConfirmation={() => {
          buttonSelect === MESSAGE_STATUS_MAPPING.confirm
            ? handleSend()
            : handleCancelDocument();
        }}
        title={`¿Estás seguro de que quieres ${buttonSelect}?`}
        isLoading={isLoadingDocuments}
      />
      <Dialogs
        open={showRejectVehicle}
        handleClose={toggleRejectVehicle}
        handleConfirmation={onRejectVehicle}
        title={`¿Estás seguro de que quieres rechazar este vehiculo?`}
        isLoading={isLoadingRejectVehicle}
      />
    </Stack>
  );
}
