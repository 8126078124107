/**BO Services */

export const COURIER_LIST = "flota.courier-list";
export const COURIER_INFO = "flota.courier-info";
export const COURIER_DELETE = "flota.courier-delete";
export const COURIER_REPORT = "flota.courier-report";
export const COURIER_FILTER = "flota.courier-filter";
export const COURIER_EDIT = "flota.courier-edit";
export const COURIER_ACTIVATE = "flota.courier-activate";

export const PEONETA_LIST = "flota.peoneta-list";
export const PEONETA_INFO = "flota.peoneta-info";
export const PEONETA_DELETE = "flota.peoneta-delete";
export const PEONETA_REPORT = "flota.peoneta-report";
export const PEONETA_FILTER = "flota.peoneta-filter";
export const PEONETA_EDIT = "flota.peoneta-edit";
export const PEONETA_ACTIVATE = "flota.peoneta-activate";

export const WALKER_LIST = "flota.walker-list";
export const WALKER_INFO = "flota.walker-info";
export const WALKER_DELETE = "flota.walker-delete";
export const WALKER_REPORT = "flota.walker-report";
export const WALKER_FILTER = "flota.walker-filter";
export const WALKER_EDIT = "flota.walker-edit";
export const WALKER_ACTIVATE = "flota.walker-activate";

export const FLEET_LIST = "flota.fleet-list";
export const FLEET_INFO = "flota.fleet-info";
export const FLEET_DELETE = "flota.fleet-delete";
export const FLEET_REPORT = "flota.fleet-report";
export const FLEET_FILTER = "flota.fleet-filter";
export const FLEET_EDIT = "flota.fleet-edit";
export const FLEET_ACTIVATE = "flota.fleet-activate";

export const PROVIDER_LIST = "flota.provider-list";
export const PROVIDER_INFO = "flota.provider-info";
export const PROVIDER_REPORT = "flota.provider-report";

export const REQUEST_LIST = "flota.request-list";
export const REQUEST_INFO = "flota.request-info";

export const APPLY_INFO = "flota.apply-info";
export const APPLY_LIST = "flota.apply-list";

export const OFFER_LIST = "flota.offer-list";
export const OFFER_ADD = "flota.offer-add";
export const OFFER_INFO = "flota.offer-info";
export const OFFER_EDIT = "flota.offer-edit";
export const OFFER_CLOSE = "flota.offer-close";
export const OFFER_DELETE = "flota.offer-delete";

export const CONFIG_LIST = "flota.config-list";

export const CONFIG_FLEET = "flota.config-fleet";
export const CONFIG_DOCUMENTS = "flota.config-documents";
export const CONFIG_BASES = "flota.config-bases";

export const SECURITY_APPLY_LIST = "flota.security-apply-list";
export const SECURITY_APPLY_INFO = "flota.security-apply-info";
export const SECURITY_REQUEST_LIST = "flota.security-request-list";
export const SECURITY_REQUEST_INFO = "flota.security-request-info";

/** Provider Services */

export const PROVIDER_PROFILE = "flota.provider-profile";

export const PROVIDER_COURIER_LIST = "flota.provider-courier-list";
export const PROVIDER_COURIER_INFO = "flota.provider-courier-info";
export const PROVIDER_COURIER_EDIT = "flota.provider-courier-edit";
export const PROVIDER_FLEET_LIST = "flota.provider-fleet-list";
export const PROVIDER_PEONETA_LIST = "flota.provider-peoneta-list";
export const PROVIDER_WALKER_LIST = "flota.provider-walker-list";

export const PROVIDER_APPLY_LIST = "flota.provider-apply-list";
export const PROVIDER_APPLY_ADD = "flota.provider-apply-add";
export const PROVIDER_APPLY_EDIT = "flota.provider-apply-edit";
export const PROVIDER_APPLY_INFO = "flota.provider-apply-info";
export const PROVIDER_APPLY_DELETE = "flota.provider-apply-delete";

export const PROVIDER_REQUESTS_LIST = "flota.provider-requests-list";
export const PROVIDER_REQUEST_ADD = "flota.provider-request-add";
export const PROVIDER_REQUEST_UPDATE = "flota.provider-request-update";
export const PROVIDER_REQUEST_DELETE = "flota.provider-request-delete";
export const PROVIDER_REQUEST_REPORT = "flota.provider-request-report";
export const PROVIDER_REQUEST_INFO = "flota.provider-request-info";

export const PROVIDER_OFFER_LIST = "flota.provider-offer-list";

export const ALL_PERMISSIONS = [
  COURIER_LIST,
  COURIER_INFO,
  COURIER_DELETE,
  COURIER_REPORT,
  COURIER_FILTER,
  COURIER_EDIT,
  COURIER_ACTIVATE,
  PEONETA_LIST,
  PEONETA_INFO,
  PEONETA_DELETE,
  PEONETA_REPORT,
  PEONETA_FILTER,
  PEONETA_EDIT,
  PEONETA_ACTIVATE,
  FLEET_LIST,
  FLEET_INFO,
  FLEET_DELETE,
  FLEET_REPORT,
  FLEET_FILTER,
  FLEET_EDIT,
  FLEET_ACTIVATE,
  PROVIDER_LIST,
  PROVIDER_INFO,
  PROVIDER_REPORT,
  REQUEST_LIST,
  REQUEST_INFO,
  APPLY_INFO,
  APPLY_LIST,
  OFFER_LIST,
  OFFER_ADD,
  OFFER_INFO,
  OFFER_EDIT,
  OFFER_CLOSE,
  OFFER_DELETE,
  CONFIG_LIST,
  CONFIG_FLEET,
  CONFIG_DOCUMENTS,
  CONFIG_BASES,
  SECURITY_APPLY_LIST,
  SECURITY_APPLY_INFO,
  SECURITY_REQUEST_LIST,
  SECURITY_REQUEST_INFO,
  PROVIDER_PROFILE,
  PROVIDER_COURIER_LIST,
  PROVIDER_COURIER_INFO,
  PROVIDER_COURIER_EDIT,
  PROVIDER_FLEET_LIST,
  PROVIDER_PEONETA_LIST,
  PROVIDER_WALKER_LIST,
  PROVIDER_APPLY_LIST,
  PROVIDER_APPLY_ADD,
  PROVIDER_APPLY_EDIT,
  PROVIDER_APPLY_INFO,
  PROVIDER_APPLY_DELETE,
  PROVIDER_REQUESTS_LIST,
  PROVIDER_REQUEST_ADD,
  PROVIDER_REQUEST_UPDATE,
  PROVIDER_REQUEST_DELETE,
  PROVIDER_REQUEST_REPORT,
  PROVIDER_REQUEST_INFO,
  PROVIDER_OFFER_LIST,
  WALKER_LIST,
  WALKER_INFO,
  WALKER_DELETE,
  WALKER_REPORT,
  WALKER_FILTER,
  WALKER_EDIT,
  WALKER_ACTIVATE,
];
