import { useContext } from "react";
import { ContextMessageContext } from "./contextMessage.context";

export const useContextMessageContext = () => {
  const contextMessageContext = useContext(ContextMessageContext);
  if (!contextMessageContext) {
    throw new Error("useContextMessageContext must be used within a ContextMessageContextProvider");
  }
  return contextMessageContext;
}
