import { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { Input } from "../../Inputs";
import { validateRut } from "../../../utils/validateRut";
import { maskRUT, maskTel } from "../../../utils/masks";
import {
  isComplexTextSpecialValid,
  validateMinLength,
} from "../../../utils/validateValues";
import employeeImg from "../../../assets/images/datos-empleados.png";
import { useAtom } from "jotai";
import { replaceEmployeeDataAtom } from "../../../v2/store/requestTracker/replaceRequest.store";
import { useValidateEmployeeByEmail } from "../../../services/hooks/Employee/useValidateEmployeeByEmail";
import { useValidateEmployeeByDni } from "../../../services/hooks/Employee/useValidateEmployeeByDni";

export const EmployeeSection = ({
  control,
  isEditing,
  isEditingProvider,
  title,
  canEditData,
  isBoleta,
}) => {
  const [currentFormEmail, setCurrentFormEmail] = useState("");
  const [currentFormDni, setCurrentFormDni] = useState("");
  const [employeeData, setEmployeeData] = useAtom(replaceEmployeeDataAtom);
  const { data: validateByEmailResult, isLoading: isGettingEmployeeByEmail } =
    useValidateEmployeeByEmail("employee", currentFormEmail);
  const { data: validateByDniResult, isLoading: isGettingEmployeeByDni } =
    useValidateEmployeeByDni("employee", currentFormDni);

  const updateFormField = (field, onChange) => (event) => {
    setEmployeeData((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
    onChange && onChange(event);
  };

  const isValidEmail = useMemo(() => {
    if (!currentFormEmail || !validateByEmailResult) return true;
    return validateByEmailResult?.isValid;
  }, [currentFormEmail, validateByEmailResult]);

  const isValidDni = useMemo(() => {
    if (!currentFormDni || !validateByDniResult) return true;
    return validateByDniResult?.isValid;
  }, [currentFormDni, validateByDniResult]);

  const allowEditField = (input) => {
    if (isEditing || isBoleta) {
      return !canEditData.find((item) => item === input);
    }
    return false;
  };

  return (
    <>
      <Box
        gridColumn={{ xs: "span 12", lg: "span 12" }}
        sx={{ marginBottom: 2 }}
      >
        <Typography
          variant="h6"
          fontFamily="Montserrat"
          fontWeight="800px"
          fontSize="24px"
          lineHeight="29.26px"
        >
          {title}
        </Typography>
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        <Box gridColumn="span 8">
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            <Box gridColumn="span 6">
              <Controller
                control={control}
                name="rut"
                defaultValue={employeeData.rut}
                rules={{
                  required: "(*) Campo obligatorio",
                  validate: {
                    rutIsValid: (rut) =>
                      validateRut(maskRUT(rut)) || "RUT inválido",
                  },
                }}
                render={({
                  field: { value, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Input
                    value={(value && maskRUT(value)) || ""}
                    onChange={(e) => {
                      e.target.value = maskRUT(e.target.value);
                      onChange(e);
                      updateFormField("rut", onChange)(e);
                    }}
                    label="RUT"
                    isRequired
                    error={Boolean(error) || !isValidDni}
                    helperText={
                      !isValidDni ? "Este RUT está en uso." : error?.message
                    }
                    inputProps={{ maxLength: 12 }}
                    disabled={allowEditField("rut")}
                    isLoading={isGettingEmployeeByDni}
                    {...rest}
                    onBlur={(event) => setCurrentFormDni(event?.target?.value)}
                  />
                )}
              />
            </Box>
            <Box gridColumn="span 6">
              <Controller
                control={control}
                name="name"
                defaultValue={employeeData.name}
                rules={{
                  required: "(*) Campo obligatorio",
                  validate: {
                    textIsValid: (text) =>
                      isComplexTextSpecialValid(text) ||
                      "Nombre inválido, no se aceptan caracteres extraños ni dígitos",
                  },
                }}
                render={({
                  field: { value, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Input
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      updateFormField("name", onChange)(e);
                    }}
                    label="Nombre"
                    isRequired
                    error={Boolean(error)}
                    helperText={error?.message}
                    disabled={allowEditField("name")}
                    {...rest}
                  />
                )}
              />
            </Box>
            <Box gridColumn="span 6">
              <Controller
                control={control}
                name="lastname1"
                defaultValue={employeeData.lastname1}
                rules={{
                  required: "(*) Campo obligatorio",
                  validate: {
                    textIsValid: (text) =>
                      isComplexTextSpecialValid(text) ||
                      "Apellido inválido, no se aceptan caracteres extraños ni dígitos",
                  },
                }}
                render={({
                  field: { value, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Input
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      updateFormField("lastname1", onChange)(e);
                    }}
                    label="Apellido Paterno"
                    isRequired
                    error={Boolean(error)}
                    helperText={error?.message}
                    disabled={allowEditField("lastname1")}
                    {...rest}
                  />
                )}
              />
            </Box>
            <Box gridColumn="span 6">
              <Controller
                control={control}
                name="lastname2"
                defaultValue={employeeData?.lastname2 ?? ""}
                rules={{
                  validate: {
                    textIsValid: (text) =>
                      isComplexTextSpecialValid(text) ||
                      "Apellido inválido, no se aceptan caracteres extraños ni dígitos",
                  },
                }}
                render={({
                  field: { value, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Input
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e);
                      updateFormField("lastname2", onChange)(e);
                    }}
                    label="Apellido Materno"
                    error={Boolean(error)}
                    helperText={error?.message}
                    disabled={allowEditField("lastname2")}
                    {...rest}
                  />
                )}
              />
            </Box>
            <Box gridColumn="span 6">
              <Controller
                control={control}
                defaultValue={employeeData.phone}
                name="phone"
                rules={{
                  required: "(*) Campo obligatorio",
                  validate: {
                    minValid: (fono) => {
                      if (fono) {
                        return (
                          validateMinLength(fono.replace(/\D/g, ""), 11) ||
                          "Teléfono no puede ser menor a 11 dígitos"
                        );
                      } else {
                        return;
                      }
                    },
                  },
                }}
                render={({
                  field: { value, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Input
                    value={maskTel(value) || ""}
                    isRequired
                    onChange={(e) => {
                      onChange(e);
                      updateFormField("phone", onChange)(e);
                    }}
                    label="Teléfono"
                    error={Boolean(error)}
                    helperText={error?.message}
                    inputProps={{ maxLength: 15 }}
                    disabled={allowEditField("phone")}
                    {...rest}
                  />
                )}
              />
            </Box>
            <Box gridColumn="span 6">
              <Controller
                control={control}
                defaultValue={employeeData.email}
                name="email"
                rules={{
                  required: "(*) Campo obligatorio",
                  validate: {
                    validateEmail: (value) => {
                      const validEmailRegex =
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                      return validEmailRegex.test(value) || "Email inválido";
                    },
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    label="Correo electrónico"
                    fullWidth
                    isRequired
                    type="text"
                    error={Boolean(error) || !isValidEmail}
                    helperText={
                      !isValidEmail
                        ? "Este correo electrónico está en uso."
                        : error?.message
                    }
                    inputProps={{ maxLength: 50 }}
                    disabled={allowEditField("email")}
                    isLoading={isGettingEmployeeByEmail}
                    {...field}
                    onBlur={(event) => {
                      setCurrentFormEmail(event?.target?.value);
                      updateFormField("email", field.onChange)(event);
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        {!isEditingProvider && (
          <Box gridColumn="span 4">
            <Box
              component="img"
              alt="Empleado"
              src={employeeImg}
              sx={{
                display: { xs: "none", lg: "block" },
                width: "244px",
                height: "251px",
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
