import React, { useCallback, useState, useMemo } from "react";
import { Stack } from "@mui/material";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { PaperWhite } from "../../../components/Papers";
import { FormCreate } from "../../../components/Forms/Employer/FormCreateEmployee";
import { Alert } from "../../../components/Alert";
import {
  useCreateEmployee,
  useDeleteHardEmployee,
} from "../../../services/hooks/Employee";
import { employeeType } from "../../../constants/employeeType";
import { useMyProvider } from "../../../services/hooks/Providers/useMyProvider";
import { useAuth } from "../../../hook/useAuth";
import { useUploadDocuments } from "../../../services/hooks/Docs/S3/useUploadDocument";
import { useUpdateEmployee } from "../../../services/hooks/Employee/useUpdateEmployee";
import {
  EMPLOYEE_TYPE_ENUM,
  VACANCY_TYPE_MAPPING,
} from "../../../constants/status";
import { useGetDocumentsByEntity } from "../../../services/hooks/Docs/S3/useGetDocumentsByEntity";
import { useUpdateDocuments } from "../../../services/hooks/Docs/S3/useUpdateDocument";
import {
  findFileByDocumentTypeId,
  findFileByLabelName,
} from "../../../utils/documents";
import { validateGroupedDocuments } from "../../../v2/helper/documents";
import { vacancyType } from "../../../constants/vacancyType";
import { useGetApplyById } from "../../../services/hooks/Apply/useGetApplyById";
import { templateNames } from "../../../constants/templateNames";
import { replaceEmployeeDataAtom } from "../../../v2/store/requestTracker/replaceRequest.store";
import { useAtom } from "jotai";

const initialAlertError = {
  open: false,
  title: "",
  messages: [],
  content: "",
};

const initialValue = {
  rut: "",
  name: "",
  lastname1: "",
  lastname2: "",
  phone: "",
  email: "",
};

export default function CreateEmployee({
  goBack,
  applyId,
  employee,
  isBoleta,
}) {
  const { email } = useAuth();
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [alertError, setAlertError] = useState(initialAlertError);
  const [, setEmployeeData] = useAtom(replaceEmployeeDataAtom);
  const { data: documentsFromBucket, isLoading: isGettingDocuments } =
    useGetDocumentsByEntity(employee?.id, "employee");
  const { mutate: createMutate, isLoading: isCreateLoading } =
    useCreateEmployee();
  const { mutate: updateMutate, isLoading: isUpdateLoading } =
    useUpdateEmployee();
  const { mutate: mutateDeleteEmployee, isLoading: isLoadingDeletingEmployee } =
    useDeleteHardEmployee();
  const { mutate: mutateUpdateDocument, isLoading: isLoadingUpdate } =
    useUpdateDocuments();
  const { mutate: mutateUploadDocument, isLoading: isLoadingUpload } =
    useUploadDocuments();
  const { data: provider, isLoading: isProviderLoading } = useMyProvider(email);
  const { data: currentApply, isLoading: isGettingApply } = useGetApplyById(
    applyId,
    provider?.id
  );

  const isEditing = useMemo(
    () => employee && documentsFromBucket,
    [employee, documentsFromBucket]
  );

  const handleConfirmation = () => {
    setAlertError({
      ...alertError,
      open: false,
    });
  };

  const handleSubmit = useCallback(
    async (employee) => {
      const offerVacancyLinked = currentApply?.offer?.setting?.vacancies.find(
        ({ vehicle }) => vehicle.name === employee.fleet?.fleetType
      );

      const data = {
        name: employee.name,
        lastname1: employee.lastname1,
        lastname2: employee.lastname2,
        numberRut: employee.rut,
        phone: employee.phone,
        email: employee.email,
        type: employee.isPeoneta ? employeeType.PEONETA : employeeType.COURIER,
        subcontract: false,
        providerId: provider?.id,
        fleet: employee.fleet,
        documentTemplateId: employee.isPeoneta
          ? templateNames.peoneta
          : offerVacancyLinked?.vehicle?.courierTemplateId,
        apply: {
          id: applyId,
        },
        termsAndConditions: employee?.termsAndConditions,
        securityConditions: employee?.securityConditions,
      };

      const action = employee?.id ? updateMutate : createMutate;

      const { error: validationGroupedError, groupedDataByDocumentType } =
        validateGroupedDocuments(currentTemplate.documentTypes, employee);

      if (validationGroupedError) {
        setAlertError({
          ...alertError,
          open: true,
          title: "Error",
          messages: validationGroupedError.messages,
        });
        return;
      }

      action(
        {
          data,
          employeeId: employee?.id,
          applyId,
        },
        {
          onSuccess: (createdEmployee) => {
            const groupedDataKeys = Object.keys(groupedDataByDocumentType);
            Object.entries(groupedDataByDocumentType).forEach(
              ([key, documentToUpload]) => {
                const beforeUpload = Object.assign({}, documentToUpload);
                beforeUpload["ownerId"] = createdEmployee?.id;
                beforeUpload["ownerType"] = vacancyType.EMPLOYEE;
                beforeUpload.metaData = JSON.stringify(beforeUpload.metaData);

                const isLastDocumentKey =
                  key === groupedDataKeys[groupedDataKeys.length - 1];

                if (isEditing) {
                  if (!beforeUpload.file.some((docFile) => docFile === null)) {
                    const currentMetadataGroupName =
                      documentToUpload.metaData[0]?.groupName;
                    const relatedDocument = documentsFromBucket.find(
                      (relDoc) =>
                        relDoc.files[0].groupName === currentMetadataGroupName
                    );
                    const relatedDocumentId = relatedDocument?.id;

                    mutateUpdateDocument(
                      {
                        documentId: relatedDocumentId,
                        documents: beforeUpload,
                      },
                      {
                        onSuccess: () => {},
                        onError: () => {
                          setAlertError({
                            ...alertError,
                            open: true,
                            title: "Error",
                            messages: [
                              "Oh no! se ha producido un error al actualizar los documentos",
                            ],
                          });
                        },
                      }
                    );
                  }
                  if (isLastDocumentKey) {
                    setEmployeeData(initialValue);
                    goBack();
                  }
                } else {
                  mutateUploadDocument(beforeUpload, {
                    onSuccess: () => {
                      if (isLastDocumentKey) {
                        setEmployeeData(initialValue);
                        goBack();
                      }
                    },
                    onError: () => {
                      mutateDeleteEmployee(
                        {
                          deleteEmployeeId: createdEmployee?.id,
                          applyId,
                        },
                        {
                          onSuccess: () => {
                            if (isLastDocumentKey) goBack();
                          },
                          onError: () => {
                            setAlertError({
                              ...alertError,
                              open: true,
                              title: "Error",
                              messages: [
                                "Oh no! se ha producido un error al eliminar el empleado",
                              ],
                            });
                          },
                        }
                      );
                      setAlertError({
                        ...alertError,
                        open: true,
                        title: "Error",
                        messages: [
                          "Oh no! se ha producido un error al cargar los documentos",
                        ],
                      });
                    },
                  });
                }
              }
            );
          },
          onError: () => {
            setAlertError({
              ...alertError,
              open: true,
              title: "Error",
              messages: [
                `Oh no! se ha producido un error al crear ${
                  employee.peoneta
                    ? VACANCY_TYPE_MAPPING.peoneta
                    : VACANCY_TYPE_MAPPING.courier
                }`,
              ],
            });
          },
        }
      );
    },
    [currentApply, currentTemplate, isEditing]
  );

  const defaultValues = useMemo(() => {
    let formValues = {};
    if (isBoleta) {
      formValues = {
        name: provider?.name,
        lastname1: provider?.lastname1,
        lastname2: provider?.lastname2,
        rut: provider?.numberRut,
        phone: provider?.phone,
        email: provider?.email,
        contractType: provider?.contractType,
        type: employeeType.COURIER,
        subcontract: false,
        providerId: provider?.id,
        apply: {
          id: applyId,
        },
        fleet: currentApply?.fleetIds[0],
      };
    }
    const documentData = currentTemplate?.documentTypes.reduce(
      (result, documentType) => {
        if (documentType?.hasExpiration) {
          const documentFound = findFileByDocumentTypeId(
            documentsFromBucket,
            documentType?.id,
            true
          );
          result[`${documentType?.name}_expiration_date`] = new Date(
            documentFound?.expirationDate
          );
        }

        documentType.documents.forEach((document) => {
          const relatedDocumentFromBucket = findFileByLabelName(
            documentsFromBucket,
            document?.label
          );
          result[document.name] = {
            documentTypeId: documentType?.id,
            status: "pending",
            ownerId: relatedDocumentFromBucket?.owner?.id,
            ownerType: relatedDocumentFromBucket?.owner?.type,
            metaData: {
              id: document?.id,
              name: relatedDocumentFromBucket?.name,
              labelName: relatedDocumentFromBucket?.labelName,
              groupName: relatedDocumentFromBucket?.groupName,
            },
          };
        });

        return { ...result };
      },
      {}
    );

    return {
      id: employee?.id,
      rut:
        employee?.employeeType === EMPLOYEE_TYPE_ENUM.COURIER
          ? employee?.courierDni
          : employee?.peonetaDni ?? employee?.rut,
      name: employee?.name ?? "",
      lastname1: employee?.lastname1 ?? "",
      lastname2: employee?.lastname2 ?? "",
      email: employee?.email ?? "",
      phone: employee?.phone ?? "",
      isPeoneta: employee?.type === employeeType.PEONETA ?? false,
      securityConditions: employee?.securityConditions,
      termsAndConditions: employee?.termsAndConditions,
      fleet: currentApply?.fleetIds.find(
        (fleet) => fleet.plate === employee?.plate
      ),
      ...formValues,
      ...documentData,
    };
  }, [
    employee,
    currentApply,
    documentsFromBucket,
    currentTemplate,
    isBoleta,
    provider,
    applyId,
  ]);

  return (
    <>
      <Stack spacing={2} sx={{ mt: 5 }}>
        <PaperWhite>
          <FormCreate
            isEditing={Boolean(defaultValues?.id)}
            defaultValues={defaultValues}
            onSubmit={handleSubmit}
            goBack={goBack}
            setTemplate={setCurrentTemplate}
            currentApply={currentApply}
            isBoleta={isBoleta}
          />
        </PaperWhite>
        {(isCreateLoading ||
          isUpdateLoading ||
          isProviderLoading ||
          isLoadingUpload ||
          isLoadingDeletingEmployee ||
          isLoadingUpdate ||
          isGettingDocuments ||
          isGettingApply) && <LoadingSpinner open />}
      </Stack>
      <Alert
        icon
        open={alertError.open}
        handleConfirmation={handleConfirmation}
        title={alertError.title}
        messages={alertError.messages}
      />
    </>
  );
}
