import { useMutation, useQueryClient } from 'react-query';
import { useApi } from '../../api';
import { APPLY_URI, FLEET_URI } from '../../../constants/api';
import { EmployeeStatus } from '../../../constants/status';


export const updateFleet = async ({ data, applyId }) => {
  const { axiosInstance } = useApi();

  const { data: newFleet } = await axiosInstance.put(`${FLEET_URI}/fleet/${data.id}`, data);

  await axiosInstance.put(`/${applyId}/vacancy`,
      {
          id: newFleet.id,
          status: newFleet.status ?? EmployeeStatus.DRAFT,
          approvation: null,
          type: 'fleet',
          plate: newFleet.plate,
          fleetType: newFleet.type,
          model: newFleet.model,
          year: newFleet.year,
          brand: newFleet.brand,
          courierDni: data.courierDni,
          peonetaDni: data.peonetaDni,
          documentTemplateId: data.documentTemplateId
      },
      {
          baseURL: APPLY_URI,
      });
  return { ...newFleet, id: newFleet?._id ?? newFleet?.id };;
}


export function useUpdateFleet(id) {
  const queryClient = useQueryClient();

  return useMutation(({ data, applyId }) => updateFleet({ data, applyId }), {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.refetchQueries('getFleetsByProviderAndOffer')
        queryClient.invalidateQueries('fleetByPlate')
        queryClient.invalidateQueries(['requestsById', id])
        queryClient.refetchQueries(['requestsById', id])
      }, 500)
    },
  })
}
