import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../api";
import { FLEET_URI, APPLY_URI } from "../../../constants/api";
import { EmployeeStatus } from "../../../constants/status";

const { axiosInstance } = useApi();

export const createFleet = async ({ data, applyId }) => {
  const { data: newFleet } = await axiosInstance.post(
    `${FLEET_URI}/fleet/`,
    data
  );

  if (applyId) {
    await axiosInstance.post(
      `/${applyId}/vacancy`,
      {
        id: newFleet._id,
        status: EmployeeStatus.DRAFT,
        approvation: null,
        type: "fleet",
        plate: newFleet.plate,
        fleetType: newFleet.type,
        model: newFleet.model,
        year: newFleet.year,
        brand: newFleet.brand,
        courierDni: data.courierDni,
        peonetaDni: data.peonetaDni,
        documentTemplateId: data.documentTemplateId,
      },
      {
        baseURL: APPLY_URI,
      }
    );
  }

  return { ...newFleet, id: newFleet?._id ?? newFleet?.id };
};

export function useCreateFleet() {
  const queryClient = useQueryClient();

  return useMutation(({ data, applyId }) => createFleet({ data, applyId }), {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.refetchQueries("getFleetsByProviderAndOffer");
        queryClient.invalidateQueries("requestsById");
        queryClient.refetchQueries("listEmployeeByApply");
        queryClient.invalidateQueries("requestsById");
        queryClient.invalidateQueries("applyById");
      }, 500);
    },
  });
}
