import { Box, Stack } from "@mui/material";
import { Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import FileUploadComponent from "../../Inputs/FileUpload";
import { renameFile } from "../../../v2/helper/files";

export const Document = ({ document, groupedDocuments, control }) => {
  const handleChange = (event, onChange) => {
    let file = event.target.files[0];
    const fileName = `${uuidv4()}.${event.target.files[0]?.name.split('.').slice(-1)[0]}`;
    file = renameFile(file, fileName);
    const loadBearingStructure = {
      file: file,
      documentTypeId: groupedDocuments?.id,
      status: "pending",
      ownerId: null,
      ownerType: null,
      metaData: {
        id: document?.id,
        name: fileName,
        labelName: document?.label,
        groupName: groupedDocuments?.name,
      },
    };
    onChange(loadBearingStructure);
  };

  return (
    <Box key={document?.id} gridColumn="span 4" sx={{mb:2}}>
      <Controller
        control={control}
        name={document?.name}
        defaultValue={""}
        rules={{ required: "(*) Campo obligatorio" }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Stack position="relative">
              <FileUploadComponent
                placeholder="Carga archivos .png, .jpg o PDF"
                value={value ? value?.metaData?.name : value || ""}
                label={document?.label}
                onChange={(e) => handleChange(e, onChange)}
                error={Boolean(error)}
                helperText={error?.message}
                isRequired
              />
            </Stack>
          )
        }}
      />
    </Box>
  );
};
