import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../../services/api";
import { APPLY_URI } from "../../../../constants/api";

const approveEmployee = async ({
  manageEmployeeId,
  manageEmployeeAction,
  activeTab,
  applyId,
}) => {
  const { axiosInstance } = useApi();

  const parameters =
    activeTab === "provider"
      ? `/provider/${manageEmployeeId}/action/${manageEmployeeAction}`
      : `${applyId}/vacancy/${manageEmployeeId}/action/${manageEmployeeAction}`;

  return axiosInstance.put(
    parameters,
    {},
    {
      baseURL: APPLY_URI,
    }
  );
};

export function useApproveEmployee() {
  const queryClient = useQueryClient();
  return useMutation(approveEmployee, {
    onSuccess: () => {
      queryClient.refetchQueries("listSecurity");
    },
  });
}
