import React, { useCallback, useEffect, useState } from "react";
import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Tabs,
  Tab,
  Button,
  Grid,
  Card,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { createSearchParams, useNavigate } from "react-router-dom";
import { MessageBox } from "../../../components/common/MessageBox";
import { SearchBar } from "../../../components/SearchBar";
import { Table } from "../../components/Table";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

import { dynamicHeadCells, getNestedValues } from "./headCells";

import { useListInterested } from "../../../services/hooks/Interested/useListInterested";
import { DocumentList } from "../../components/Documents/DocumentList";
import { EmployeeStatus } from "../../../constants/status";
import { ChipStatus } from "../../components/Chip";
import { useURLfilters } from "../../hooks/filters.hook";
import { formatDate, formatOfferContractType } from "../../../utils/formatValues";
import { useListBasesCodes } from "../../services/hooks/Bases/useListBasesCodes";
import { SelectorInput } from "../Requests/components/SelectorInput";
import { objectToTypeOptionList } from "../../helper/converters";
import {
  ENTITY_TYPES,
  PROVIDER_ASSETS_TYPE,
} from "../../../constants/entityTypes";

const defaultFilter = {
  type: "employee",
  subType: "courier",
  status: "pending",
  page: 1,
  limit: 10,
};

export const entityfilters = {
  provider: {
    type: ENTITY_TYPES.EMPLOYEE,
    subType: PROVIDER_ASSETS_TYPE.PROVIDER,
    page: 1,
  },
  courier: {
    type: ENTITY_TYPES.EMPLOYEE,
    subType: PROVIDER_ASSETS_TYPE.COURIER,
    page: 1,
  },
  peoneta: {
    type: ENTITY_TYPES.EMPLOYEE,
    subType: PROVIDER_ASSETS_TYPE.PEONETA,
    page: 1,
  },
  fleet: {
    type: ENTITY_TYPES.FLEET,
    subType: undefined,
    page: 1,
  },
};

export default function ListApply() {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState();
  const [currentHeadCells, setCurrentHeadCells] = useState([]);

  const {
    filters,
    setFilters,
    getURLValue,
    cleanFilters,
    getArrayFromURLValue,
  } = useURLfilters(defaultFilter, {
    persistParamOnClear: ["type", "subType", "status"],
  });
  const { data, isLoading, error } = useListInterested(filters);
  const [dataRecord, setDataRecord] = useState();
  const [isRenderedForm, setIsRenderedForm] = useState(false);
  const { data: listGeographiesCodes, isLoading: basesLoading } =
    useListBasesCodes();
  const [baseCode, setBaseCode] = useState(getURLValue("base", undefined));

  const onBaseChange = (event) => {
    const baseValue = event.target.value;
    setBaseCode(baseValue);
    const base = baseValue && baseValue.length === 0 ? undefined : baseValue;
    setFilters({ base, page: 1 });
  };
  const cleanAllFilters = () => {
    cleanFilters();
    setBaseCode(undefined);
  };

  const BaseCodeSelector = useCallback(
    ({ onChange, value }) => {
      if (!listGeographiesCodes) return null;
      const bases = getArrayFromURLValue("base", undefined);
      return (
        <SelectorInput
          label="Seleccionar Base"
          value={value ?? bases}
          onChange={onChange}
          options={objectToTypeOptionList(listGeographiesCodes)}
        />
      );
    },
    [listGeographiesCodes, baseCode]
  );

  const { TableContainer, setPage } = Table(
    currentHeadCells,
    {
      ...data?.metadata,
      page: getURLValue("page", 1),
      limit: getURLValue("limit", 10),
    },
    onChangeFilters
  );

  const handleSearch = (event) => {
    const { value } = event.target;

    const searchInput = value.toLowerCase();
    if (searchInput !== undefined) {
      setFilters({ page: 1, search: searchInput });
      setPage(0);
    }
  };

  function onChangeFilters(newFilters = {}) {
    setFilters(newFilters);
  }

  const handleGoBack = () => {
    setIsRenderedForm(false);
  };

  useEffect(() => {
    const type = getURLValue("subType") || getURLValue("type");
    setCurrentHeadCells(dynamicHeadCells[type]);
  }, [filters]);

  if (isRenderedForm) {
    return <DocumentList goBack={handleGoBack} dataRecord={dataRecord} />;
  }

  if (error) return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading || basesLoading) return <LoadingSpinner open />;

  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <SearchBar
        id="table-searchbar"
        type="text"
        placeholder="Buscar"
        onSearch={handleSearch}
        search={getURLValue("search", "")}
      />
      <Button
        variant="contained"
        onClick={cleanAllFilters}
        size="medium"
        sx={{ marginLeft: 3 }}
      >
        Limpiar filtros
      </Button>
    </Stack>
  );
  const onChangeTab = (tab) => {
    setCurrentTab(tab);
    setFilters(entityfilters[tab]);
  };

  const HeaderTabs = () => (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Tabs
        value={currentTab ?? getURLValue("tab") ?? PROVIDER_ASSETS_TYPE.COURIER}
        onChange={(e, searchInput) => {
          cleanFilters();
          onChangeTab(searchInput);
          setFilters({ base: baseCode, page: 1 });
          navigate({
            pathname: "",
            search: `${createSearchParams({
              tab: searchInput,
            })}`,
          });
        }}
      >
        <Tab label="Proveedores" value={PROVIDER_ASSETS_TYPE.PROVIDER} />
        <Tab label="Vehículos" value={ENTITY_TYPES.FLEET} />
        <Tab label="Conductores" value={PROVIDER_ASSETS_TYPE.COURIER} />
        <Tab label="Peonetas" value={PROVIDER_ASSETS_TYPE.PEONETA} />
      </Tabs>
    </Stack>
  );

  return (
    <Stack spacing={2}>
      <Grid
        sx={{
          justifyContent: "space-between",
        }}
        container
      >
        <Typography variant="h4" align="center">
          Postulaciones
        </Typography>
        {currentTab !== PROVIDER_ASSETS_TYPE.PROVIDER && (
          <Grid>
            <BaseCodeSelector onChange={onBaseChange} value={baseCode} />
          </Grid>
        )}
      </Grid>
      <Grid
        sx={{
          justifyContent: "center",
        }}
        container
        spacing={2}
      >
        <Grid item xs={12} sm={8} md={6}>
          <Card
            sx={{
              borderRadius: "16px",
              boxShadow: "0 8px 16px 0 #BDC9D7",
            }}
          >
            <HeaderTabs />
          </Card>
        </Grid>
      </Grid>
      <TableContainer addons={[TabsAddon]} withPagination>
        <TableBody>
          {data?.data &&
            data?.data.map((record) => {
              return (
                <TableRow key={record?.id}>
                  {currentHeadCells?.map((headCell) => {
                    return (
                      <>
                        {headCell.id === "actions" ? (
                          <TableCell>
                            <Stack direction="row" spacing={1.5}>
                              <IconButton
                                onClick={() => {
                                  setDataRecord(record);
                                  setIsRenderedForm(true);
                                }}
                              >
                                <InfoOutlined />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        ) : headCell.id === "legalRepresentative.name" ? (
                          <TableCell>
                            <Typography>
                              {`${record.legalRepresentative?.name} ${
                                record.legalRepresentative?.lastname1 ?? ""
                              } ${record.legalRepresentative?.lastname2 ?? ""
                              }`.trim()}
                            </Typography>
                          </TableCell>
                        ) : headCell.id === "dateApply" ? (
                          <TableCell key={headCell.id}>
                            <Typography>
                              {formatDate(record?.dateApply, "DD/MM/YYYY")}
                            </Typography>
                          </TableCell>
                        ) : ["status", "vacancy.status"].includes(
                          headCell.id
                        ) ? (
                          <TableCell key={headCell.id}>
                            <ChipStatus
                              label={
                                EmployeeStatus[
                                  getNestedValues(headCell.id, record)
                                ]
                              }
                              setcolor={getNestedValues(
                                headCell.id,
                                record
                              )}
                            />
                          </TableCell>
                        ) : headCell.id === "offerContractType" || headCell.id === "contractType" ? (
                          <TableCell key={headCell.id}>
                            <Typography>
                              {
                                formatOfferContractType(getNestedValues(headCell.id, record))
                              }
                            </Typography>
                          </TableCell>
                        ) : (
                          <TableCell key={headCell.id}>
                            <Typography>
                              {getNestedValues(headCell.id, record)}
                            </Typography>
                          </TableCell>
                        )}
                      </>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </TableContainer>
    </Stack>
  );
}
