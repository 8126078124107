import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../api'
import { EMPLOYEE_URI, APPLY_URI } from '../../../constants/api'

const deleteEmployee = async ({ deleteEmployeeId, applyId }) => {
  const { axiosInstance } = useApi();

  if(applyId){
    await axiosInstance.delete(`${APPLY_URI}/${applyId}/vacancy/${deleteEmployeeId}/by-type/employee`);
  }

  return axiosInstance.delete(`/hard/${deleteEmployeeId}`, {
    baseURL: EMPLOYEE_URI,
  });
}

export function useDeleteHardEmployee() {
  const queryClient = useQueryClient()

  return useMutation(deleteEmployee,
    {
      onSuccess: () => {
        queryClient.refetchQueries('getFleetsByProviderAndOffer')
        queryClient.refetchQueries('listEmployeeByApply')
        queryClient.invalidateQueries('requestsById')
        queryClient.invalidateQueries('applyById')
      },
    })
}

