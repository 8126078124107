import {
  Box,
  Stack,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import { Input } from '../../../components/Inputs'
import { SearchAddress } from "../../Inputs";
import {
  isNumberValid,
} from '../../../utils/validateValues'

export const AddressSectionProvider = ({ control, completeDirection, title }) => {
  return (

    <Stack spacing={2} sx={{mt:2}}>
      <Box gridColumn={{ xs: 'span 12', lg: 'span 12' }}>
        <Typography variant='h6' sx={{ fontWeight: 700 }}>
          {title}
        </Typography>
      </Box>

      <Stack sx={{ bgcolor: '#EAF8FF', borderRadius: '16px'}}>
        <Stack mx={2} mt={2} mb={2}>
          <Typography fontFamily="Lato" fontSize={16} sx={{ fontWeight: 800, mb: 2 }}>
            Busca tu dirección aquí o sigue ingresando la información manualmente
          </Typography>
          <SearchAddress
            onSelected={completeDirection}
            placeholder="Ingresa la dirección"
            sx={{ bgcolor: 'white', borderRadius: '16px'}}
          />
        </Stack>
      </Stack>
      <Box
          display='grid'
          gridTemplateColumns='repeat(12, 1fr)'
          gap={6}
          sx={{ py: 6 }}
        >
        <Box gridColumn={{ xs: 'span 12', lg: 'span 8' }}>
          <Controller
            control={control}
            name='streetProvider'
            rules={{
              required: '(*) Campo obligatorio',
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={value || ''}
                onChange={onChange}
                label='Dirección'
                fullWidth
                isRequired
                error={!value && Boolean(error)}
                helperText={!value && error?.message}
                inputProps={{ maxLength: 50 }}
                {...rest}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 8', lg: 'span 2' }}>
          <Controller
            control={control}
            name='numberProvider'
            rules={{
              required: '(*) Campo obligatorio',
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={value || ''}
                onChange={onChange}
                label='Número'
                fullWidth
                isRequired
                error={!value && Boolean(error)}
                helperText={!value && error?.message}
                inputProps={{ maxLength: 5 }}
                {...rest}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            name='cityProvider'
            rules={{
              required: '(*) Campo obligatorio',
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={value || ''}
                onChange={onChange}
                label='Ciudad'
                fullWidth
                isRequired
                error={!value && Boolean(error)}
                helperText={!value && error?.message}
                inputProps={{ maxLength: 50 }}
                {...rest}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            name='communeProvider'
            rules={{
              required: '(*) Campo obligatorio',
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={value || ''}
                onChange={onChange}
                label='Comuna'
                fullWidth
                isRequired
                error={!value && Boolean(error)}
                helperText={!value && error?.message}
                inputProps={{ maxLength: 50 }}
                {...rest}
              />
            )}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', lg: 'span 4' }}>
          <Controller
            control={control}
            name='zipCodeProvider'
            rules={{
              validate: {
                numberIsValid: (num) =>
                  isNumberValid(num) ||
                  'Cód. Postal inválido, debe ser numérico',
              },
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Input
                value={value || ''}
                onChange={onChange}
                label='Cód. Postal'
                fullWidth
                error={!value && Boolean(error)}
                helperText={!value && error?.message}
                inputProps={{ maxLength: 30 }}
                {...rest}
              />
            )}
          />
        </Box>
      </Box>
    </Stack>
  )
}